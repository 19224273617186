import { AuthProvider } from "@/auth/AuthProvider";
import ChangePassword from "@/auth/ChangePassword";
import ForgotPassword from "@/auth/ForgotPassword";
import ResetPassword from "@/auth/ResetPassword";
import AxiosInterceptors from "@/components/AxiosInterceptors";
import { isInRole } from "@/fns";
import useAuth from "@/hooks/useAuth";
import useQuerystring from "@/hooks/useQuerystring";
import modalList from "@/modals";
import CompanyList from "@/pages/admin/companies/CompanyList";
import CompanyUserList from "@/pages/admin/companies/CompanyUserList";
import GpsDeviceList from "@/pages/admin/gpsDevices/GpsDeviceList";
import AlertIndex from "@/pages/alerts/AlertIndex";
import GeofenceList from "@/pages/geofences/GeofenceList";
import MapVehicleAlerts from "@/pages/map/MapVehicleAlerts";
import MapVehiclePositions from "@/pages/map/MapVehiclePositions";
import MapVehicleRoute from "@/pages/map/MapVehicleRoute";
import ReportMenu from "@/pages/reports/ReportMenu";
import ScheduledReportList from "@/pages/reports/ScheduledReportList";
import UserList from "@/pages/users/UserList";
import VehicleGroupList from "@/pages/vehicleGroups/VehicleGroupList";
import VehicleList from "@/pages/vehicles/VehicleList";
import theme from "@/theme";
import { AppShell, LoadingOverlay, MantineProvider, Title } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  Route,
  HashRouter as Router,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import Login from "../auth/Login";
import Header from "./Header";
import Navbar from "./Navbar";

Sentry.init({
  dsn: "https://cabd058044cf4d778b3f91c5b84fcc1f@o4504691074596864.ingest.sentry.io/4504691122831360",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
  enabled: import.meta.env.VITE_SENTRY_ENVIRONMENT !== "development",
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const AdminRoute = () => {
  const { token, tokenLoading, claims } = useAuth();

  if (tokenLoading) return <LoadingOverlay visible />;
  if (!token) return <Navigate to="/auth/login" state={{ from: location }} replace />;
  if (!isInRole(claims, "SuperAdmin")) return <Title order={1}>You do not have access to this function</Title>;

  return <Outlet />;
};

const ProtectedRoute = () => {
  const { token, tokenLoading, invalidAuthToken } = useAuth();
  const location = useLocation();

  if (tokenLoading) return <LoadingOverlay visible />;
  if (invalidAuthToken) return <Title order={1}>Invalid Auth Token</Title>;
  if (!token) return <Navigate to="/auth/login" state={{ from: location }} replace />;

  return <Outlet />;
};

const EmbeddedAppShell = ({ children }) => {
  const qs = useQuerystring();

  const [showHeader] = useState(qs.get("embed") !== "true" && qs.get("embed") !== "noheader");
  const [showMenu] = useState(qs.get("embed") !== "true");

  return (
    <AppShell navbar={showMenu && <Navbar />} header={showHeader && <Header />}>
      {children}
    </AppShell>
  );
};

const App = () => {
  return (
    <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
      <Notifications />
      <QueryClientProvider client={queryClient}>
        <ModalsProvider modals={modalList}>
          <Router>
            <AuthProvider>
              <AxiosInterceptors />
              <EmbeddedAppShell>
                <SentryRoutes>
                  <Route path="/" element={<Navigate replace to="/vehicles" />} />
                  <Route path="auth" element={<Outlet />}>
                    <Route path="login" element={<Login />} />
                    <Route path="forgotPassword" element={<ForgotPassword />} />
                    <Route path="resetPassword" element={<ResetPassword />} />
                    <Route path="changePassword" element={<ChangePassword />} />
                  </Route>
                  <Route path="map" element={<ProtectedRoute />}>
                    <Route index element={<MapVehiclePositions />} />
                    <Route path="vehicle/:vehicleId/alerts" element={<MapVehicleAlerts />} />
                    <Route path="vehicle/:vehicleId" element={<MapVehicleRoute />} />
                  </Route>
                  <Route path="vehicles" element={<ProtectedRoute />}>
                    <Route index element={<VehicleList />} />
                  </Route>
                  <Route path="users" element={<ProtectedRoute />}>
                    <Route index element={<UserList />} />
                  </Route>
                  <Route path="reports" element={<ProtectedRoute />}>
                    <Route index element={<ReportMenu />} />
                    <Route path="schedules" element={<ScheduledReportList />} />
                  </Route>
                  <Route path="groups" element={<ProtectedRoute />}>
                    <Route index element={<VehicleGroupList />} />
                  </Route>
                  <Route path="geofences" element={<ProtectedRoute />}>
                    <Route index element={<GeofenceList />} />
                  </Route>
                  <Route path="alerts" element={<ProtectedRoute />}>
                    <Route index element={<AlertIndex />} />
                  </Route>
                  <Route path="admin" element={<AdminRoute />}>
                    <Route path="companies" element={<Outlet />}>
                      <Route index element={<CompanyList />} />
                      <Route path=":companyId/users" element={<CompanyUserList />} />
                    </Route>
                    <Route path="gpsDevices" element={<Outlet />}>
                      <Route index element={<GpsDeviceList />} />
                    </Route>
                  </Route>
                </SentryRoutes>
              </EmbeddedAppShell>
            </AuthProvider>
          </Router>
        </ModalsProvider>
      </QueryClientProvider>
    </MantineProvider>
  );
};

export default App;

import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import SimpleTable from "@/components/SimpleTable";
import { showErrorToast, showSuccessToast } from "@/fns";
import useAuth from "@/hooks/useAuth";
import { openAlertRuleEditModal } from "@/modals/AlertRuleEditModal";
import { ActionIcon, LoadingOverlay, Switch, Tooltip } from "@mantine/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FaPencilAlt } from "react-icons/fa";

const getParam = (params, name) => params.find((p) => p.key == name)?.value;

const columnDef = [
  { accessorKey: "alertName", header: "Event", metadata: { width: 4 } },
  {
    accessorKey: "enabled",
    header: "Enabled",
    cell: ({ cell }) => <Switch checked={cell.getValue()} />,
    metadata: { width: 1 },
  },
  { accessorKey: "recipients", header: "Email Recipients", metadata: { width: 4 } },
  { accessorKey: "scheduleName", header: "Schedule", metadata: { width: 3 } },
];

const alertTypes = [
  { key: "speed", name: "Speeding" },
  { key: "acceleration", name: "Rapid Acceleration" },
  { key: "deceleration", name: "Harsh Braking" },
  { key: "idle", name: "Idling" },
  { key: "ignition_on", name: "Ignition On" },
  { key: "ignition_off", name: "Ignition Off" },
  { key: "plugged_in", name: "Unplugged" },
  { key: "state_crossing", name: "State Line Crossed" },
  { key: "diagnostic", name: "Diagnostic Message" },
];

const AlertRuleList = ({ alertSchedules }) => {
  const queryClient = useQueryClient();
  const { claims } = useAuth();
  const companyId = claims.CompanyId;

  const [data, setData] = useState([]);

  const { error, isLoading, isError } = useQuery(["companies", companyId], () => api.getCompany(companyId), {
    onSuccess: (data) => {
      const _data = alertTypes.map((a) => {
        const scheduleId = getParam(data.parameters, "alert_scheduleid_" + a.key);
        const scheduleName = scheduleId
          ? alertSchedules.find((s) => s.scheduleId.toString() == scheduleId)?.name ?? ""
          : "";

        return {
          alertKey: a.key,
          alertName: a.name,
          recipients: getParam(data.parameters, "alert_recipients_" + a.key),
          enabled: getParam(data.parameters, "alert_recipients_" + a.key) && scheduleId,
          scheduleId,
          scheduleName,
          idleDuration: a.key == "idle" ? getParam(data.parameters, "idle_alert_duration") : null,
        };
      });

      setData(_data);
    },
  });

  const handleEditRule = (alertData) => {
    openAlertRuleEditModal({ alertData, onSave: (data) => handleSaveRule(alertData.alertKey, data) });
  };

  const handleSaveRule = async (key, data) => {
    try {
      await api.updateAlertRule(companyId, { ...data, key });
      showSuccessToast("Saved");
    } catch (err) {
      showErrorToast("Save Failed", err.message);
    } finally {
      queryClient.invalidateQueries(["companies", companyId]);
    }
  };

  if (isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  const columns = [
    ...columnDef,
    {
      id: "actions",
      cell: ({ row }) => (
        <Tooltip label="Edit Rule">
          <ActionIcon color="green" variant="outline" onClick={() => handleEditRule(row.original)}>
            <FaPencilAlt />
          </ActionIcon>
        </Tooltip>
      ),
      metadata: { align: "right" },
    },
  ];

  return (
    <SimpleTable
      data={data}
      columns={columns}
      title="Alert Rules"
      initialSort={[{ id: "alertName", desc: false }]}
      showPagination={false}
    />
  );
};

export default AlertRuleList;

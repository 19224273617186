import GeofenceEdit from "@/pages/geofences/GeofenceEdit";
import { openContextModal } from "@mantine/modals";

const GeofenceEditModal = ({ context, id, innerProps }) => {
  const handleSave = (data) => {
    context.closeModal(id);
    innerProps.onSave(data);
  };

  const handleCancel = () => {
    context.closeModal(id);
  };

  return <GeofenceEdit id={innerProps.id} onSave={handleSave} onCancel={handleCancel} />;
};

const openGeofenceEditModal = (innerProps) => {
  openContextModal({
    modal: "GeofenceEditModal",
    size: "xl",
    centered: true,
    withCloseButton: false,
    closeOnClickOutside: false,
    closeOnEscape: false,
    innerProps,
  });
};

export { GeofenceEditModal, openGeofenceEditModal };

import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import PhoneInput from "@/components/PhoneInput";
import { Button, Checkbox, Group, LoadingOverlay, PasswordInput, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQuery } from "@tanstack/react-query";

const UserEdit = ({ id, onSave, onCancel }) => {
  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      isAdmin: false,
    },
    validate: {
      password: (val) => (!!id || val.length >= 7 ? null : "Password must be at least 7 characters"),
      confirmPassword: (val, values) => (!!id || val == values.password ? null : "Passwords do not match"),
    },
  });

  const { error, isLoading, isError } = useQuery(["users", id], () => api.getUser(id), {
    onSuccess: (data) => form.setValues({ ...data }),
    enabled: !!id,
  });

  const handleSubmit = (values) => {
    onSave(values);
  };

  if (id && isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput required data-autofocus autoFocus label="Name" {...form.getInputProps("name")} />
        <TextInput required label="Email" {...form.getInputProps("email")} autoComplete="off" />
        <PhoneInput label="Phone #" {...form.getInputProps("phoneNumber")} />
        {!id && (
          <>
            <PasswordInput required label="Password" {...form.getInputProps("password")} autoComplete="new-password" />
            <PasswordInput
              required
              label="Confirm Password"
              {...form.getInputProps("confirmPassword")}
              autoComplete="new-password"
            />
          </>
        )}
        <Title order={5}>User Roles</Title>
        <Checkbox label="Administrator" {...form.getInputProps("isAdmin", { type: "checkbox" })} />
        <Group position="center" mt="xl">
          <Button type="submit">{id ? "Save" : "Add"}</Button>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default UserEdit;

import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import HereMap from "@/components/HereMap";
import { createResizableRect } from "@/mapFns";
import {
  Button,
  Checkbox,
  Group,
  Input,
  LoadingOverlay,
  MultiSelect,
  SegmentedControl,
  Stack,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

const prepareVehicleOptions = (vehicles) => {
  if (!vehicles || vehicles.length === 0) return [];

  return vehicles.map((v) => ({
    value: v.vehicleId,
    label: v.vehicleName + (v.driverName ? ` (${v.driverName.trim()})` : ""),
  }));
};

const checkNameExists = async (id, name) => {
  try {
    const existing = await api.getGeofenceByName(name);
    return existing.geofenceId != id;
  } catch (err) {
    console.log(err.message);
    return false;
  }
};

const GeofenceEdit = ({ id, onSave, onCancel }) => {
  const [bounds, setBounds] = useState();
  const [rectGroup, setRectGroup] = useState();
  const [rectCreated, setRectCreated] = useState(false);
  const [mapError, setMapError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const mapRef = useRef();

  const mode = id ? "edit" : "add";

  const form = useForm({
    initialValues: {
      name: "",
      alertEmails: "",
      alertSms: "",
      active: true,
      allVehicles: false,
      allVehiclesToggle: "selected",
      north: 0.0,
      south: 0.0,
      east: 0.0,
      west: 0.0,
      textDriver: false,
      alertOnEnter: true,
      alertOnExit: true,
    },
  });

  const {
    data: geofence,
    error,
    isLoading,
  } = useQuery(["geofences", id], () => api.getGeofence(id), {
    onSuccess: (data) =>
      form.setValues({
        ...data,
        allVehiclesToggle: data.allVehicles ? "all" : "selected",
        vehicleIds: data.vehicles?.map((v) => v.vehicleId),
      }),
    enabled: !!id,
  });

  const { data: vehicles } = useQuery(["vehicles"], api.getVehicles);

  useEffect(() => {
    if (mode == "add") {
      form.reset();
    }

    if (mode == "edit") {
      if (geofence && mapRef.current) {
        const xd = Math.abs(geofence.west) - Math.abs(geofence.east);
        const yd = Math.abs(geofence.north) - Math.abs(geofence.south);

        const mapBounds = {
          maxLat: geofence.north + yd,
          maxLng: geofence.east + xd,
          minLat: geofence.south - yd,
          minLng: geofence.west - xd,
        };

        setBounds(mapBounds);

        const geofenceBounds = {
          maxLat: geofence.north,
          maxLng: geofence.east,
          minLat: geofence.south,
          minLng: geofence.west,
        };

        const _rectGroup = createResizableRect(
          mapRef.current.map,
          mapRef.current.behavior,
          geofenceBounds,
          handleResize
        );

        setRectGroup(_rectGroup);
        setRectCreated(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, geofence]);

  const handleResize = (rect) => {
    form.setValues({
      north: rect.getTop(),
      south: rect.getBottom(),
      east: rect.getRight(),
      west: rect.getLeft(),
    });
  };

  const handleDrawGeofence = () => {
    const viewport = mapRef.current.map.getViewModel().getLookAtData().bounds.getBoundingBox();

    const xd = Math.abs(viewport.getLeft()) - Math.abs(viewport.getRight());
    const yd = Math.abs(viewport.getTop()) - Math.abs(viewport.getBottom());

    const geofenceBounds = {
      maxLat: viewport.getTop() - yd * 0.25,
      maxLng: viewport.getRight() - xd * 0.25,
      minLat: viewport.getBottom() + yd * 0.25,
      minLng: viewport.getLeft() + xd * 0.25,
    };

    form.setValues({
      north: geofenceBounds.maxLat,
      south: geofenceBounds.minLat,
      east: geofenceBounds.maxLng,
      west: geofenceBounds.minLng,
    });

    const _rectGroup = createResizableRect(mapRef.current.map, mapRef.current.behavior, geofenceBounds, handleResize);

    setRectGroup(_rectGroup);
    setRectCreated(true);
  };

  const handleRemoveGeofence = () => {
    if (rectGroup) {
      mapRef.current.map.removeObject(rectGroup);

      setRectGroup(null);
      setRectCreated(false);

      form.setValues({
        north: 0.0,
        south: 0.0,
        east: 0.0,
        west: 0.0,
      });
    }
  };

  const handleSubmit = async (values) => {
    if (!rectCreated) {
      setMapError("Please use the button above to create a geofence rectangle on the map.");
      return;
    }

    const nameExists = await checkNameExists(id, values.name);

    if (nameExists) {
      form.setFieldError("name", "Name already exists");
    } else {
      if (submitting) return;
      setSubmitting(true);
      await onSave({ ...values, allVehicles: values.allVehiclesToggle === "all" });
      setSubmitting(false);
    }
  };

  if (id && isLoading) return <LoadingOverlay visible />;
  if (error) return <ErrorAlert message={error.message} />;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="xs">
        <Group align="flex-end">
          <TextInput required label="Name" {...form.getInputProps("name")} style={{ flexGrow: 1 }} />
          <Group mb="xs">
            <Text size="sm">Alert On:</Text>
            <Checkbox
              styles={{ label: { paddingLeft: "0.5rem" } }}
              {...form.getInputProps("alertOnEnter", { type: "checkbox" })}
              label="Entry"
            />
            <Checkbox
              styles={{ label: { paddingLeft: "0.5rem" } }}
              {...form.getInputProps("alertOnExit", { type: "checkbox" })}
              label="Exit"
            />
          </Group>
          <Switch
            size="xl"
            onLabel="Active"
            offLabel="Inactive"
            {...form.getInputProps("active", { type: "checkbox" })}
          />
        </Group>
        <Group align="flex-end">
          <Stack spacing={0}>
            <Input.Label>Applies To</Input.Label>
            <SegmentedControl
              data={[
                { value: "all", label: "All Vehicles" },
                { value: "selected", label: "Selected Vehicles" },
              ]}
              {...form.getInputProps("allVehiclesToggle")}
            />
          </Stack>
          <MultiSelect
            placeholder="Choose Vehicles..."
            data={prepareVehicleOptions(vehicles)}
            searchable
            disabled={form.values.allVehiclesToggle == "all"}
            {...form.getInputProps("vehicleIds")}
          />
        </Group>
        <Group align="flex-end">
          <TextInput label="Send Email Alerts To" {...form.getInputProps("alertEmails")} style={{ flexGrow: 1 }} />
          <TextInput label="Send Text (SMS) Alerts To" {...form.getInputProps("alertSms")} style={{ flexGrow: 1 }} />
          <Checkbox
            mb="sm"
            label="Text Driver"
            labelPosition="right"
            {...form.getInputProps("textDriver", { type: "checkbox" })}
          />
        </Group>
        {!rectCreated && (
          <Text align="center">
            Zoom the map to the desired area, then
            <Button compact mx={5} onClick={handleDrawGeofence}>
              Click Here to Draw a Geofence
            </Button>
            {mapError && <Text color="red">{mapError}</Text>}
          </Text>
        )}
        <div style={{ width: "100%", height: "450px", maxHeight: "50vh" }}>
          <HereMap bounds={bounds} ref={mapRef} />
        </div>

        <Group position="center">
          {rectCreated && (
            <Button type="button" variant="filled" color="red" onClick={handleRemoveGeofence}>
              Re-Draw Geofence on Map
            </Button>
          )}
          <Button type="submit" disabled={submitting}>
            {id ? "Save" : "Add"}
          </Button>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default GeofenceEdit;

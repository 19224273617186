import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import SimpleTable from "@/components/SimpleTable";
import { formatDate, showErrorToast, showSuccessToast } from "@/fns";
import useAppState from "@/hooks/useAppState";
import useAuth from "@/hooks/useAuth";
import { openCompanyAddModal } from "@/modals/CompanyAddModal";
import { openCompanyEditModal } from "@/modals/CompanyEditModal";
import { ActionIcon, Button, Group, LoadingOverlay, Tooltip } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { noop } from "@tanstack/react-table";
import { FaPencilAlt, FaSignInAlt, FaTrashAlt, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const columnDef = [
  { accessorKey: "name", header: "Name", metadata: { width: 4 } },
  {
    accessorKey: "created",
    cell: (props) => formatDate(props.row.getValue("created")),
    header: "Date Created",
    metadata: { width: 1 },
  },
];

const CompanyList = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { onImpersonate } = useAuth();
  const { startImpersonating, stopImpersonating } = useAppState();

  const { data, error, isLoading, isError } = useQuery(["companies"], api.getCompanies);

  const handleAddCompany = () => {
    openCompanyAddModal({
      onSave: async (data) => {
        try {
          await api.createCompany(data);
          showSuccessToast("Company Added");
        } catch (err) {
          showErrorToast("Save Failed", err.message);
        } finally {
          queryClient.invalidateQueries(["companies"]);
        }
      },
    });
  };

  const handleEditCompany = (id) => {
    openCompanyEditModal({
      companyId: id,
      onSave: async (data) => {
        try {
          await api.updateCompany(id, data);
          showSuccessToast("Company Saved");
        } catch (err) {
          showErrorToast("Save Failed", err.message);
        } finally {
          queryClient.invalidateQueries(["companies"]);
        }
      },
    });
  };

  const handleDeleteCompany = (id) => {
    openConfirmModal({
      title: "Are you sure you want to delete this company?",
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: noop,
      onConfirm: () => doDeleteCompany(id),
    });
  };

  const doDeleteCompany = async (id) => {
    try {
      await api.deleteCompany(id);
      showSuccessToast("Company Deleted");
    } catch (err) {
      showErrorToast("Error deleting company", err.message);
    } finally {
      queryClient.invalidateQueries(["companies"]);
    }
  };

  const handleImpersonateCompany = (id) => {
    openConfirmModal({
      title: "Are you sure you want to log in as this company?",
      labels: { confirm: "Yes", cancel: "No" },
      onCancel: noop,
      onConfirm: () => doImpersonate(id),
    });
  };

  const doImpersonate = async (id) => {
    const company = data.find((c) => c.companyId === id);

    if (!company) {
      showErrorToast("Error", "Invalid Company");
      return;
    }

    try {
      await onImpersonate(id);
      startImpersonating(company);
      navigate("/");
    } catch (err) {
      showErrorToast("Error", err.message);
      stopImpersonating();
    }
  };

  const columns = [
    ...columnDef,
    {
      id: "actions",
      cell: ({ row }) => (
        <Group position="right" noWrap>
          <Tooltip label="Edit Company">
            <ActionIcon color="green" variant="outline" onClick={() => handleEditCompany(row.original.companyId)}>
              <FaPencilAlt />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Users">
            <ActionIcon color="green" variant="outline" onClick={() => navigate(`${row.original.companyId}/users`)}>
              <FaUsers />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Log In As">
            <ActionIcon
              color="green"
              variant="outline"
              onClick={() => handleImpersonateCompany(row.original.companyId)}
            >
              <FaSignInAlt />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Delete Company">
            <ActionIcon color="red" variant="outline" onClick={() => handleDeleteCompany(row.original.companyId)}>
              <FaTrashAlt />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
      metadata: { width: 1 },
    },
  ];

  if (isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  return (
    <SimpleTable
      title="Companies"
      data={data}
      columns={columns}
      rightSection={<Button onClick={handleAddCompany}>Add a Company</Button>}
      initialSort={[{ id: "name", desc: false }]}
    />
  );
};

export default CompanyList;

import carIdling from "./car-idling.png";
import carMovingEast from "./car-moving-e.png";
import carMovingNorth from "./car-moving-n.png";
import carMovingSouth from "./car-moving-s.png";
import carMovingWest from "./car-moving-w.png";
import carMoving from "./car-moving.png";
import carNoSignal from "./car-no-signal.png";
import carOffline from "./car-offline.png";
import carStopped from "./car-stopped.png";

const getIconHeading = (heading) => {
  if (heading == null) return "";
  if (heading >= 315 || heading < 45) return "N";
  if (heading >= 45 && heading < 135) return "E";
  if (heading >= 135 && heading < 225) return "S";
  if (heading >= 225 && heading < 315) return "W";
};

export {
  carIdling,
  carMoving,
  carMovingEast,
  carMovingNorth,
  carMovingSouth,
  carMovingWest,
  carNoSignal,
  carOffline,
  carStopped,
  getIconHeading,
};

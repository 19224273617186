import { AlertRuleEditModal } from "./AlertRuleEditModal";
import { AlertScheduleEditModal } from "./AlertScheduleEditModal";
import { CompanyAddModal } from "./CompanyAddModal";
import { CompanyEditModal } from "./CompanyEditModal";
import { GeofenceEditModal } from "./GeofenceEditModal";
import { GpsDeviceEditModal } from "./GpsDeviceEditModal";
import { ReportSchedulerModal } from "./ReportSchedulerModal";
import { UserEditModal } from "./UserEditModal";
import { VehicleEditModal } from "./VehicleEditModal";
import { VehicleGroupEditModal } from "./VehicleGroupEditModal";

const modalList = {
  AlertRuleEditModal,
  AlertScheduleEditModal,
  CompanyAddModal,
  CompanyEditModal,
  GeofenceEditModal,
  GpsDeviceEditModal,
  ReportSchedulerModal,
  UserEditModal,
  VehicleEditModal,
  VehicleGroupEditModal,
};

export default modalList;

import { formatTime } from "@/fns";
import { Box, Button, Group, Stack, Text, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { BsTriangleFill } from "react-icons/bs";

const msToMinutes = (ms) => ms / 1000 / 60;

const MapTimeline = ({ segments, selectedTime, onSelectSegment }) => {
  const [bounds, setBounds] = useState({ minTime: null, maxTime: null, totalMinutes: 0, hours: [] });

  useEffect(() => {
    if (segments && segments.length > 0) {
      const minTime = dayjs(segments[0].endTimeLocal).minute(0).second(0);
      const maxTime = dayjs(segments[segments.length - 1].startTimeLocal)
        .add(1, "hour")
        .minute(0)
        .second(0);

      const _hours = [];
      for (let i = minTime.hour(); i < maxTime.hour(); i++) {
        _hours.push(i);
      }

      setBounds({
        minTime,
        maxTime,
        totalMinutes: msToMinutes(maxTime - minTime).toFixed(0),
        hours: _hours,
      });
    }
  }, [segments]);

  const getWidthPct = (segment) => {
    const start = dayjs(segment.startTimeLocal || bounds.minTime);
    const end = dayjs(segment.endTimeLocal || bounds.maxTime);
    const minutes = msToMinutes(end - start);
    return (minutes / bounds.totalMinutes) * 100;
  };

  const getTooltip = (segment) => {
    const start = dayjs(segment.startTimeLocal || bounds.minTime);
    const end = dayjs(segment.endTimeLocal || bounds.maxTime);
    const minutes = msToMinutes(end - start);
    const status = segment.segmentType;

    return `${status} ${
      status == "Driving" || !segment.streetAddress ? "" : `at ${segment.streetAddress}`
    } from ${formatTime(start)} for ${minutes.toFixed(1)} minutes`;
  };

  if (!segments || segments.length == 0 || bounds.hours.length == 0) return null;

  const selectedHour = selectedTime ? selectedTime.hour() : null;
  const selectedMinute = selectedTime ? selectedTime.minute() : null;

  return (
    <Box mt={10}>
      <Group position="apart">
        <Stack spacing={4} style={{ flexGrow: 1 }}>
          <Group spacing={0}>
            {segments.map((d, i) => {
              const status = d.segmentType;
              const color = status == "Idling" ? "yellow" : status == "Driving" ? "green" : "red";
              return (
                <Tooltip key={i} label={getTooltip(d)}>
                  <Text bg={color} c={color} w={`${getWidthPct(d)}%`} onClick={() => onSelectSegment(i, status)}>
                    .
                  </Text>
                </Tooltip>
              );
            })}
          </Group>
          <Group spacing={0}>
            {bounds.hours.map((h) => (
              <Text
                key={h}
                w={`${100 / bounds.hours.length}%`}
                pl={4}
                style={{ border: "gray 1px solid", borderWidth: 1, borderTopWidth: 0, position: "relative" }}
              >
                {dayjs(`2023-01-01 ${h}:00`).format("h A")}
                {selectedHour == h && (
                  <div style={{ position: "absolute", top: "0", left: `${(selectedMinute / 60) * 100}%` }}>
                    <BsTriangleFill color="#40C057" />
                  </div>
                )}
              </Text>
            ))}
          </Group>
        </Stack>
        <Box>
          <Button onClick={() => onSelectSegment(null, null)} h="100%" py={6} lh="1.5em">
            Reset
            <br />
            Zoom
          </Button>
        </Box>
      </Group>
    </Box>
  );
};

export default MapTimeline;

import VehicleGroupEdit from "@/pages/vehicleGroups/VehicleGroupEdit";
import { openContextModal } from "@mantine/modals";

const VehicleGroupEditModal = ({ context, id, innerProps }) => {
  const handleSave = (data) => {
    context.closeModal(id);
    innerProps.onSave(data);
  };

  const handleCancel = () => {
    context.closeModal(id);
  };

  return <VehicleGroupEdit id={innerProps.id} onSave={handleSave} onCancel={handleCancel} />;
};

const openVehicleGroupEditModal = (innerProps) => {
  openContextModal({
    modal: "VehicleGroupEditModal",
    size: "md",
    title: `${innerProps.id ? "Edit" : "Add a"} Vehicle Group`,
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    innerProps,
  });
};

export { VehicleGroupEditModal, openVehicleGroupEditModal };

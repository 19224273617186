import { showErrorToast } from "@/fns";
import useAuth from "@/hooks/useAuth";
import useQuerystring from "@/hooks/useQuerystring";
import { Alert, Button, Center, Container, Group, Paper, PasswordInput, Text, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const { onResetPassword } = useAuth();
  const qs = useQuerystring();

  const [success, setSuccess] = useState(false);

  const form = useForm({
    initialValues: {
      newPassword: "",
    },
    validate: {
      newPassword: (val) => (val.length < 7 ? "Password must be at least 7 characters" : null),
    },
  });

  const handleSubmit = async (values) => {
    try {
      await onResetPassword(qs.get("email"), qs.get("token"), values.newPassword);
      setSuccess(true);
    } catch (err) {
      showErrorToast("Reset Password Failed", err.message);
    }
  };

  const validLink = qs.get("token") && qs.get("email");

  const pageBody = success ? (
    <Alert color="green" title="Password Reset Successfully" />
  ) : validLink ? (
    <>
      <Title order={4}>Reset Password</Title>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <PasswordInput
          label="New Password"
          autoComplete="new-password"
          required
          withAsterisk={false}
          {...form.getInputProps("newPassword")}
        />
        <Group position="center">
          <Button type="submit" mt={10}>
            Save New Password
          </Button>
        </Group>
      </form>
    </>
  ) : (
    <Alert color="red" title="Invalid password reset link" />
  );

  return (
    <Container size={300} mt={50}>
      <Paper p="md" shadow="xs">
        {pageBody}
        <Center mt={10}>
          <Text size="sm">
            <Link to="/auth/login">Back to Log In</Link>
          </Text>
        </Center>
      </Paper>
    </Container>
  );
};

export default ResetPassword;

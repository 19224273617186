import { showErrorToast } from "@/fns";
import useAuth from "@/hooks/useAuth";
import { Alert, Button, Container, Group, Paper, PasswordInput, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";

const ChangePassword = () => {
  const { onChangePassword } = useAuth();

  const [success, setSuccess] = useState(false);

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validate: {
      newPassword: (val) => (val.length < 7 ? "Password must be at least 7 characters" : null),
      confirmNewPassword: (val, values) => (val !== values.newPassword ? "Passwords do not match" : null),
    },
  });

  const handleSubmit = async (values) => {
    try {
      await onChangePassword(values.username, values.password, values.newPassword);
      setSuccess(true);
    } catch (err) {
      showErrorToast("Change Password Failed", err.message);
    }
  };

  return (
    <Container size={300} mt={50}>
      <Paper p="md" shadow="xs">
        {success ? (
          <Alert color="green" title="Password changed successfully" />
        ) : (
          <>
            <Title order={4} mb={10}>
              Change Password
            </Title>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Stack>
                <TextInput
                  label="Email Address"
                  required
                  withAsterisk={false}
                  autoFocus
                  {...form.getInputProps("username")}
                />

                <PasswordInput
                  label="Current Password"
                  required
                  withAsterisk={false}
                  {...form.getInputProps("password")}
                />

                <PasswordInput
                  label="New Password"
                  description="Must be at least 7 characters"
                  autoComplete="new-password"
                  required
                  withAsterisk={false}
                  {...form.getInputProps("newPassword")}
                />

                <PasswordInput
                  label="Confirm New Password"
                  autoComplete="new-password"
                  required
                  withAsterisk={false}
                  {...form.getInputProps("confirmNewPassword")}
                />

                <Group position="center">
                  <Button type="submit" mt={10}>
                    Change Password
                  </Button>
                </Group>
              </Stack>
            </form>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default ChangePassword;

import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { BsCheckCircle, BsExclamationCircle } from "react-icons/bs";

dayjs.extend(utc);

const canParseInt = (s) => !!s && !isNaN(s);

const cardinalToOrdinal = (num) => {
  const j = num % 10,
    k = num % 100;
  if (j == 1 && k != 11) {
    return num + "st";
  }
  if (j == 2 && k != 12) {
    return num + "nd";
  }
  if (j == 3 && k != 13) {
    return num + "rd";
  }
  return num + "th";
};

const coalesceObject = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      obj[propName] = "";
    }
  }
  return obj;
};

const downloadUrl = (url, name) => {
  const link = document.createElement("a");

  link.href = url;
  link.target = "_blank";
  link.download = name;

  document.body.appendChild(link);

  // "dispatchEvent" is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  document.body.removeChild(link);
};

const EVENTS = {
  IGNITION_ON: 1,
  IGNITION_OFF: 2,
  PERIODIC_IGNITION_ON: 3,
  PERIODIC_IGNITION_OFF: 4,
  ACCELERATION_ALERT: 10,
  BATTERY_DISCONNECTED: 15,
  BATTERY_RECONNECTED: 16,
  SPEED_ALERT: 19,
  ODOMETER: 23,
  MOVEMENT_START: 34,
  MOVEMENT_STOP: 35,
  IDLE_START: 39,
  IDLE_STOP: 40,
  IDLE_PERIODIC: 41,
  POWER_UP_BEST_TIME: 42,
  ACCELERATION_ORIENTATION: 60,
};

const eventMap = {
  IgnitionOff: "Ignition Off",
  IgnitionOn: "Ignition On",
  IgnitionOnPeriodic: "Driving",
  IgnitionOffPeriodic: "Parked",
  AccelerationAlert: "Acceleration Alert",
  Unplugged: "Unplugged",
  SpeedAlert: "Speed Alert",
  IdleStart: "Idling",
  IdleStop: "Driving",
  IdlePeriodic: "Idling",
};

const formatDate = (d) => dayjs(d).format("MM/DD/YYYY");
const formatIsoDate = (d) => new Date(d).toISOString().split("T")[0];
const formatUtcDateToLocal = (d, fmt = "MM/DD/YYYY") => dayjs.utc(d).local().format(fmt);

const formatTime = (d) => Intl.DateTimeFormat("en-US", { timeStyle: "short" }).format(new Date(d)).toLowerCase();
const formatUtcTimeToLocal = (d) => (d ? dayjs.utc(d).local().format("h:mm A") : "");

const formatHeading = (h) => {
  if (h >= 348.75 || h < 11.25) return "N";
  if (h >= 11.25 && h < 33.75) return "NNE";
  if (h >= 33.75 && h < 56.25) return "NE";
  if (h >= 56.25 && h < 78.75) return "ENE";
  if (h >= 78.75 && h < 101.25) return "E";
  if (h >= 101.25 && h < 123.75) return "ESE";
  if (h >= 123.75 && h < 146.25) return "SE";
  if (h >= 146.25 && h < 168.75) return "SSE";
  if (h >= 168.75 && h < 191.25) return "S";
  if (h >= 191.25 && h < 213.75) return "SSW";
  if (h >= 213.75 && h < 236.25) return "SW";
  if (h >= 236.25 && h < 258.75) return "WSW";
  if (h >= 258.75 && h < 281.25) return "W";
  if (h >= 281.25 && h < 303.75) return "WNW";
  if (h >= 303.75 && h < 326.25) return "NW";
  if (h >= 326.25 && h < 348.75) return "NNW";
};

// "LastWeek" -> "Last Week"; "ThisMonth" -> "This Month"
const formatRelativeDate = (d) => d.replace("Week", " Week").replace("Month", " Month");

const formatRelativeDateTime = (d) => {
  let res = "";
  if (d) {
    if (formatUtcDateToLocal(d) != formatDate(new Date())) {
      res += formatUtcDateToLocal(d, "M/DD/YY") + " ";
    }
    res += formatUtcTimeToLocal(d);
  }
  return res;
};

// use as 2nd param to JSON.stringify to avoid circular references
const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

const isInRole = (claims, role) =>
  claims?.["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]?.includes(role) ?? false;

const militaryToAmPm = (time) => {
  const [hour, minute] = time.split(":");
  const h = parseInt(hour);
  const ampm = h >= 12 ? " pm" : " am";
  const h12 = h % 12 || 12;
  return h12 + ":" + minute + ampm;
};

const parseValidationError = (err) =>
  /*
  <ValidationProblem>: {
    "errors": {
      "PasswordTooShort": ["Passwords must be at least 7 characters."],
      "PasswordRequiresNonAlphanumeric":["Passwords must have at least one non alphanumeric character."]
    }
  }
  */
  err?.errors
    ? Object.keys(err.errors)
        .map((key) => err.errors[key])
        .join(", ")
    : null;

const phraseToCamelCase = (str) =>
  str
    .split(" ")
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");

const showErrorToast = (title, message) =>
  showNotification({
    title,
    message,
    color: "red",
    icon: <BsExclamationCircle />,
    autoClose: false,
    styles: {
      root: { backgroundColor: "#FFE3E3" },
      title: { fontWeight: "bold" },
    },
  });

const showSuccessToast = (title, message) =>
  showNotification({
    title,
    message,
    color: "green",
    icon: <BsCheckCircle />,
    styles: {
      root: { backgroundColor: "#D3F9D8" },
      title: { fontWeight: "bold" },
    },
  });

export {
  canParseInt,
  cardinalToOrdinal,
  coalesceObject,
  downloadUrl,
  eventMap,
  EVENTS,
  formatDate,
  formatHeading,
  formatIsoDate,
  formatRelativeDate,
  formatRelativeDateTime,
  formatUtcDateToLocal,
  formatTime,
  formatUtcTimeToLocal,
  getCircularReplacer,
  isInRole,
  militaryToAmPm,
  parseValidationError,
  phraseToCamelCase,
  showErrorToast,
  showSuccessToast,
};

import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import SimpleTable from "@/components/SimpleTable";
import { cardinalToOrdinal, formatRelativeDate, militaryToAmPm, showErrorToast, showSuccessToast } from "@/fns";
import { openReportSchedulerModal } from "@/modals/ReportSchedulerModal";
import { ActionIcon, Group, LoadingOverlay, Switch, Text, Tooltip } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { noop } from "@tanstack/react-table";
import { useState } from "react";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const cronToString = (cron) => {
  // strip out the "cron(...)" wrapper
  cron = cron.replace("cron(", "").replace(")", "").split(" ");

  const time = cron[1] + ":" + cron[0];
  let res = " at " + militaryToAmPm(time);

  if (cron[2] == "*") {
    res = "Daily" + res;
  } else if (cron[2] == "?") {
    let weekDays = cron[4].split(",").map((d) => daysOfWeek[parseInt(d) - 1]);
    res = "Weekly on " + weekDays.join(", ") + res;
  } else {
    let dayNumber = parseInt(cron[2]);
    res = "Monthly on the " + cardinalToOrdinal(dayNumber) + res;
  }
  return res;
};

const columnDef = [
  { accessorKey: "description", header: "Report" },
  { accessorKey: "relativeDate", header: "Date Range", cell: ({ cell }) => formatRelativeDate(cell.getValue()) },
  { accessorKey: "emailTo", header: "Email To" },
  { accessorKey: "cronExpression", header: "Schedule", cell: ({ cell }) => cronToString(cell.getValue()) },
];

const ScheduledReportList = () => {
  const queryClient = useQueryClient();
  const [data, setData] = useState([]);

  const { error, isLoading, isError } = useQuery(["scheduledReports"], api.getScheduledReports, {
    onSuccess: (data) => setData(data.map((d) => ({ ...d, ...JSON.parse(d.lambdaInput) }))),
  });

  const handleEditSchedule = (schedule) => {
    openReportSchedulerModal({
      title: schedule.description,
      data: schedule,
      onSave: (data) => handleSaveSchedule({ ...schedule, ...data }),
    });
  };

  const handleSaveSchedule = async (data) => {
    try {
      await api.updateScheduledReport(data.id, data);
      showSuccessToast("Saved");
    } catch (err) {
      showErrorToast("Save Failed", err.message);
    } finally {
      queryClient.invalidateQueries(["scheduledReports"]);
    }
  };

  const handleToggleActive = async (id, currentEnabled) => {
    try {
      await api.patchScheduledReport(id, { fieldName: "enabled", fieldValue: !currentEnabled });
    } catch (err) {
      showErrorToast("Save Failed", err.message);
    } finally {
      queryClient.invalidateQueries(["scheduledReports"]);
    }
  };

  const handleDeleteSchedule = async (id) => {
    openConfirmModal({
      title: "Delete this scheduled report?",
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: noop,
      onConfirm: () => doDeleteSchedule(id),
    });
  };

  const doDeleteSchedule = async (id) => {
    try {
      await api.deleteScheduledReport(id);
    } catch (err) {
      showErrorToast("Error deleting scheduled report", err.message);
    } finally {
      queryClient.invalidateQueries(["scheduledReports"]);
    }
  };

  if (isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  const columns = [
    ...columnDef,
    {
      accessorKey: "enabled",
      header: "Enabled",
      cell: ({ cell, row }) => (
        <Switch
          styles={{ track: { cursor: "pointer" } }}
          checked={cell.getValue()}
          onClick={() => handleToggleActive(row.original.id, row.original.enabled)}
        />
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <Group position="right" noWrap>
          <Tooltip label="Edit Scheduled Report">
            <ActionIcon color="green" variant="outline" onClick={() => handleEditSchedule(row.original)}>
              <FaPencilAlt />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Delete Scheduled Report">
            <ActionIcon color="red" variant="outline" onClick={() => handleDeleteSchedule(row.original.id)}>
              <FaTrashAlt />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
      metadata: { align: "right" },
    },
  ];

  return (
    <>
      <SimpleTable
        data={data}
        columns={columns}
        title="Scheduled Reports"
        initialSort={[{ id: "description", desc: false }]}
      />
      {data.length == 0 && (
        <Text italic align="center">
          No scheduled reports found
        </Text>
      )}
    </>
  );
};

export default ScheduledReportList;

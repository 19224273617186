import SimpleList from "@/components/SimpleList";
import { Box, Button, Card, Collapse, Group, ScrollArea, Stack, Text, TextInput } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useState } from "react";

const bgColors = {
  Stopped: "red.2",
  Moving: "green.2",
  Idling: "blue.2",
  Unknown: "gray.2",
};

const VehicleFilterPanel = ({
  vehicles,
  vehicleGroups,
  onSelectVehicle,
  onSelectGroup,
  onToggleFilters,
  onClearFilter,
}) => {
  const { height: vh } = useViewportSize();

  const [showSection, setShowSection] = useState("vehicles");
  const [search, setSearch] = useState("");

  return (
    <Card p="xs" withBorder>
      <Stack spacing="xs" w={235}>
        {false && (
          <Group position="apart">
            <Button compact variant="filled" onClick={() => onToggleFilters(false)}>
              Hide Filters
            </Button>
          </Group>
        )}

        <Box>
          <Button fullWidth onClick={() => setShowSection("vehicles")}>
            Vehicles
          </Button>
        </Box>

        <Box display={showSection == "vehicles" ? "block" : "none"}>
          <Group spacing="xs">
            <TextInput
              size="xs"
              placeholder="Search"
              style={{ flexGrow: 1 }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button compact variant="filled" onClick={onClearFilter}>
              All
            </Button>
          </Group>
        </Box>

        <ScrollArea.Autosize
          mah={vh - 350}
          style={{ flexGrow: 1 }}
          mx="-xs"
          px="xs"
          display={showSection == "groups" ? "block" : "none"}
        >
          <Collapse in={showSection == "vehicles"} transitionDuration={200}>
            <SimpleList>
              {vehicles
                .filter(
                  (v) =>
                    !search ||
                    `${v.vehicleName}#${v.year}#${v.make}#${v.model}#${v.driverName}`
                      .toLowerCase()
                      .includes(search.toLowerCase())
                )
                .sort((a, b) => (a.vehicleName < b.vehicleName ? -1 : 1))
                .map((v) => (
                  <Text
                    size="sm"
                    key={v.vehicleId}
                    style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    onClick={() => onSelectVehicle(v)}
                    bg={bgColors[v.status]}
                  >
                    {v.vehicleName || `${v.year} ${v.make} ${v.model}`}
                    {v.driverName ? ` - ${v.driverName}` : ""}
                  </Text>
                ))}
            </SimpleList>
          </Collapse>
        </ScrollArea.Autosize>

        <Box>
          <Button fullWidth onClick={() => setShowSection("groups")}>
            Vehicle Groups
          </Button>
        </Box>

        <ScrollArea.Autosize
          style={{ flexGrow: 1, maxHeight: "calc(100vh-150px)" }}
          mx="-xs"
          px="xs"
          display={showSection == "groups" ? "block" : "none"}
        >
          <Collapse in={showSection == "groups"} transitionDuration={200}>
            <SimpleList>
              {vehicleGroups
                .sort((a, b) => (a.name < b.name ? -1 : 1))
                .map((g) => (
                  <Text size="sm" key={g.groupId} onClick={() => onSelectGroup(g)}>
                    {g.name}
                  </Text>
                ))}
            </SimpleList>
          </Collapse>
        </ScrollArea.Autosize>
      </Stack>
    </Card>
  );
};

export default VehicleFilterPanel;

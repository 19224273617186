const tz = [
  { displayName: "US-Alaska", ianaName: "America/Anchorage", offset: -9.0 },
  { displayName: "US-Arizona", ianaName: "America/Phoenix", offset: -7.0 },
  { displayName: "US-Central", ianaName: "America/Chicago", offset: -6.0 },
  { displayName: "US-Eastern", ianaName: "America/New_York", offset: -5.0 },
  { displayName: "US-Hawaii", ianaName: "Pacific/Honolulu", offset: -10.0 },
  { displayName: "US-Mountain", ianaName: "America/Denver", offset: -7.0 },
  { displayName: "US-Pacific", ianaName: "America/Los_Angeles", offset: -8.0 },
  /*
  { displayName: "CA-Atlantic", ianaName: "America/Halifax", offset: 0.0 },
  { displayName: "CA-Central", ianaName: "America/Winnipeg", offset: 0.0 },
  { displayName: "CA-Eastern", ianaName: "America/Toronto", offset: 0.0 },
  { displayName: "CA-Mountain", ianaName: "America/Edmonton", offset: 0.0 },
  { displayName: "CA-Newfoundland", ianaName: "America/St_Johns", offset: 0.0 },
  { displayName: "CA-Pacific", ianaName: "America/Vancouver", offset: 0.0 },
  { displayName: "CA-Saskatchewan", ianaName: "America/Regina", offset: 0.0 },
  { displayName: "CA-Yukon", ianaName: "America/Whitehorse", offset: 0.0 },
  */
];

export default tz;

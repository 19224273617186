import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import PhoneInput from "@/components/PhoneInput";
import { coalesceObject } from "@/fns";
import { Button, Group, LoadingOverlay, NumberInput, Select, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQuery } from "@tanstack/react-query";

const deviceTypeOptions = [
  { label: "XT-2050", value: "2050" },
  { label: "XT-2150", value: "2150" },
  { label: "XT-2450", value: "2450" },
  { label: "XT-2469", value: "2469" },
];

const GpsDeviceEdit = ({ vehicleId, onSave, onCancel }) => {
  const form = useForm({
    initialValues: {
      companyId: "",
      vehicleName: "",
      year: "",
      make: "",
      model: "",
      miles: 0,
      devicePhoneNumber: "",
      meId: "",
      uid: "",
      serialNumber: "",
      modelNumber: "",
      deviceType: "",
      firmwareVersion: "",
      lastProgrammedDate: "",
      driverEmployeeId: "",
      driverName: "",
      driverPhoneNumber: "",
      warrantyDate: "",
      customerWarrantyDate: "",
    },
  });

  const { error, isLoading, isError } = useQuery(["vehicles", vehicleId], () => api.getVehicle(vehicleId), {
    enabled: !!vehicleId,
    onSuccess: (data) => form.setValues({ ...coalesceObject(data) }),
  });

  const { data: companies, isLoading: companiesLoading } = useQuery(["companies"], api.getCompanies, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const handleSubmit = (values) => {
    onSave(values);
  };

  if ((vehicleId && isLoading) || companiesLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="lg">
        <Select
          required
          label="Company"
          data={companies
            ?.map((c) => ({ label: c.name, value: c.companyId }))
            .sort((a, b) => a.label.localeCompare(b.label))}
          {...form.getInputProps("companyId")}
        />
        <Group position="apart" spacing="xl" align="flex-start" grow>
          <Stack spacing="xs">
            <Title order={5}>Vehicle Info</Title>
            <TextInput data-autofocus autoFocus label="Vehicle Name" {...form.getInputProps("vehicleName")} />
            <TextInput label="Year" {...form.getInputProps("year")} />
            <TextInput label="Make" {...form.getInputProps("make")} />
            <TextInput label="Model" {...form.getInputProps("model")} />
            <NumberInput required hideControls label="Mileage" {...form.getInputProps("miles")} />
          </Stack>
          <Stack spacing="xs">
            <Title order={5}>Device Info</Title>
            <PhoneInput required label="Device Phone #" {...form.getInputProps("devicePhoneNumber")} />
            <TextInput required label="MEID" {...form.getInputProps("meId")} />
            <TextInput required label="UID" {...form.getInputProps("uid")} />
            <TextInput required label="Serial #" {...form.getInputProps("serialNumber")} />
            <TextInput required label="Model #" {...form.getInputProps("modelNumber")} />
            <Select label="Device Type" data={deviceTypeOptions} {...form.getInputProps("deviceType")} />
            <TextInput label="Firmware Version" {...form.getInputProps("firmwareVersion")} />
            <TextInput type="date" label="Date Last Programmed" {...form.getInputProps("lastProgrammedDate")} />
          </Stack>
          <Stack spacing="xs">
            <Title order={5}>Driver & Warranty Info</Title>
            <TextInput label="Driver Employee ID" {...form.getInputProps("driverEmployeeId")} />
            <TextInput label="Driver Name" {...form.getInputProps("driverName")} />
            <PhoneInput label="Driver Phone #" {...form.getInputProps("driverPhoneNumber")} />
            <TextInput type="date" label="Warranty Date - Device" {...form.getInputProps("warrantyDate")} />
            <TextInput type="date" label="Warranty Date - Customer" {...form.getInputProps("customerWarrantyDate")} />
          </Stack>
        </Group>

        <Group position="center">
          <Button type="submit">Save</Button>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default GpsDeviceEdit;

import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import SimpleTable from "@/components/SimpleTable";
import { showErrorToast, showSuccessToast } from "@/fns";
import { openVehicleGroupEditModal } from "@/modals/VehicleGroupEditModal";
import { ActionIcon, Button, Group, LoadingOverlay, Text, Tooltip } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { noop } from "@tanstack/react-table";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

const columnDef = [{ accessorKey: "name", header: "Name", sortingFn: "text", metadata: { width: 1 } }];

const VehicleGroupList = () => {
  const queryClient = useQueryClient();

  const { data, error, isLoading, isError } = useQuery(["vehicleGroups"], api.getVehicleGroups);

  const handleAddGroup = () => {
    openVehicleGroupEditModal({ id: null, onSave: (data) => handleSaveGroup(null, "create", data) });
  };

  const handleEditGroup = (groupId) => {
    openVehicleGroupEditModal({ id: groupId, onSave: (data) => handleSaveGroup(groupId, "update", data) });
  };

  const handleSaveGroup = async (groupId, mode, data) => {
    try {
      if (mode === "create") {
        await api.createVehicleGroup(data);
      } else {
        await api.updateVehicleGroup(groupId, data);
      }
      showSuccessToast("Saved");
    } catch (err) {
      showErrorToast("Save Failed", err.message);
    } finally {
      queryClient.invalidateQueries(["vehicleGroups"]);
    }
  };

  const handleDeleteGroup = async (groupId) => {
    openConfirmModal({
      title: "Are you sure you want to delete this group?",
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: noop,
      onConfirm: () => doDeleteGroup(groupId),
    });
  };

  const doDeleteGroup = async (id) => {
    try {
      await api.deleteVehicleGroup(id);
    } catch (err) {
      showErrorToast("Error deleting group", err.message);
    } finally {
      queryClient.invalidateQueries(["vehicleGroups"]);
    }
  };

  if (isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  const columns = [
    ...columnDef,
    {
      id: "actions",
      cell: ({ row }) => (
        <Group position="right">
          <Tooltip label="Edit Group">
            <ActionIcon color="green" variant="outline" onClick={() => handleEditGroup(row.original.groupId)}>
              <FaPencilAlt />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Delete Group">
            <ActionIcon color="red" variant="outline" onClick={() => handleDeleteGroup(row.original.groupId)}>
              <FaTrashAlt />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
      metadata: { align: "right" },
    },
  ];

  return (
    <>
      <SimpleTable
        data={data}
        columns={columns}
        title="Vehicle Groups"
        rightSection={<Button onClick={handleAddGroup}>Add a Group</Button>}
        initialSort={[{ id: "name", desc: false }]}
      />
      {data.length == 0 && (
        <Text italic align="center">
          No groups found
        </Text>
      )}
    </>
  );
};

export default VehicleGroupList;

import { Alert } from "@mantine/core";
import { FaExclamationCircle } from "react-icons/fa";

const ErrorAlert = ({ message }) => (
  <Alert icon={<FaExclamationCircle size={16} />} title="Error" color="red">
    {message}
  </Alert>
);

export default ErrorAlert;

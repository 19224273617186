import {
  FaMapMarkedAlt,
  FaRegCalendarAlt,
  FaRegClock,
  FaRegListAlt,
  FaRegObjectUngroup,
  FaTruck,
  FaUsers,
} from "react-icons/fa";
import { TbFence } from "react-icons/tb";

const MenuLinks = [
  { icon: <FaMapMarkedAlt size="2em" />, route: "/map", label: "Map" },
  { icon: <FaTruck size="2em" />, route: "/vehicles", label: "Vehicles" },
  {
    icon: <FaRegListAlt size="2em" />,
    route: "/reports",
    label: "Reports",
  },
  {
    icon: <FaRegCalendarAlt size="2em" />,
    route: "/reports/schedules",
    label: "Scheduled Reports",
  },
];

const ConfigLinks = [
  { icon: <FaRegClock size="2em" />, route: "/alerts", label: "Alerts" },
  { icon: <TbFence size="2em" />, route: "/geofences", label: "Geofences" },
  { icon: <FaUsers size="2em" />, route: "/users", label: "Users" },
  { icon: <FaRegObjectUngroup size="2em" />, route: "/groups", label: "Vehicle Groups" },
];

export { MenuLinks, ConfigLinks };

import CompanyEdit from "@/pages/admin/companies/CompanyEdit";
import { openContextModal } from "@mantine/modals";

const CompanyEditModal = ({ context, id, innerProps }) => {
  const handleSave = (data) => {
    context.closeModal(id);
    innerProps.onSave(data);
  };

  const handleCancel = () => {
    context.closeModal(id);
  };

  return <CompanyEdit companyId={innerProps.companyId} onSave={handleSave} onCancel={handleCancel} />;
};

const openCompanyEditModal = (innerProps) => {
  openContextModal({
    modal: "CompanyEditModal",
    size: "md",
    title: "Edit Company",
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    innerProps,
  });
};

export { CompanyEditModal, openCompanyEditModal };

import { Box } from "@mantine/core";

const CircledText = ({ children, color }) => (
  <Box
    style={{
      borderRadius: "50%",
      width: "24px",
      height: "24px",
      padding: "3px",
      background: `${color || "#000"}`,
      border: "1px solid #000",
      color: "#fff",
      textAlign: "center",
      font: "14px Arial",
    }}
  >
    {children}
  </Box>
);

export default CircledText;

import { mapMarkerSvg } from "@/assets/svgIcons";
import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js";
import { Box } from "@mantine/core";
import React, { useEffect, useRef } from "react";
import AddressAutocomplete from "./AddressAutocomplete";

const expandRect = (rect, percent) => {
  let top = rect.getTop();
  let bottom = rect.getBottom();
  let left = rect.getLeft();
  let right = rect.getRight();

  const expandWidthBy = (right - left) * (percent / 100);
  const expandHeightBy = (top - bottom) * (percent / 100);

  top += expandHeightBy;
  bottom -= expandHeightBy;
  left -= expandWidthBy;
  right += expandWidthBy;

  return new H.geo.Rect(top, left, bottom, right);
};

const HereMap = React.forwardRef(function HereMapFn({ markers, bounds, children }, ref) {
  const divRef = useRef();
  const mapRef = useRef();

  const handleResize = () => mapRef.current.getViewPort().resize();

  const initMap = () => {
    const platform = new H.service.Platform({
      apikey: import.meta.env.VITE_HERE_MAPS_API_KEY,
    });

    const engineType = H.Map.EngineType["HARP"];

    const layers = platform.createDefaultLayers({ engineType });

    const _map = new H.Map(divRef.current, layers.vector.normal.map, {
      engineType,
      pixelRatio: window.devicePixelRatio,
      center: { lat: 0, lng: 0 },
      zoom: 2,
    });

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(_map));

    const ui = H.ui.UI.createDefault(_map, layers);

    let _bounds =
      bounds instanceof H.geo.Rect
        ? bounds
        : new H.geo.Rect(
            bounds?.maxLat ?? 49.382808,
            bounds?.minLng ?? -124.736342,
            bounds?.minLat ?? 24.521208,
            bounds?.maxLng ?? -66.945392
          );

    _map.getViewModel().setLookAtData({
      bounds: expandRect(_bounds, 10),
    });

    mapRef.current = _map;
    ref.current = { map: _map, behavior, ui };

    window.addEventListener("resize", handleResize);
  };

  useEffect(() => {
    initMap();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (markers && mapRef.current) {
      markers.forEach((m) => mapRef.current.addObject(m));
    }
  }, [markers]);

  useEffect(() => {
    if (bounds && mapRef.current) {
      const _bounds =
        bounds instanceof H.geo.Rect
          ? bounds
          : new H.geo.Rect(bounds.maxLat, bounds.minLng, bounds.minLat, bounds.maxLng);

      mapRef.current.getViewModel().setLookAtData({
        bounds: expandRect(_bounds, 10),
      });
    }
  }, [bounds]);

  const handleJumpToLocation = (location) => {
    const m = new H.map.Marker(
      { lat: location.location.lat, lng: location.location.lng },
      { icon: new H.map.Icon(mapMarkerSvg) }
    );
    mapRef.current.addObject(m);

    mapRef.current.getViewModel().setLookAtData({
      bounds: new H.geo.Rect(location.bounds.north, location.bounds.west, location.bounds.south, location.bounds.east),
    });
  };

  return (
    <Box style={{ position: "relative", width: "100%", height: "100%" }} ref={divRef}>
      <div style={{ position: "absolute", width: "300px", maxWidth: "50%", right: "20px", top: "20px", zIndex: "2" }}>
        <AddressAutocomplete onSelect={handleJumpToLocation} />
      </div>
      {children}
    </Box>
  );
});

export default HereMap;

import TinyButton from "@/components/TinyButton";
import useIsPhone from "@/hooks/useIsPhone";
import { Button, Card, Group, Stack, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";

const today = dayjs().toDate();
const yesterday = dayjs().subtract(1, "day").toDate();
const firstOfMonth = dayjs().startOf("month").toDate();
const lastOfMonth = dayjs().endOf("month").toDate();
const firstOfLastMonth = dayjs().subtract(1, "month").startOf("month").toDate();
const lastOfLastMonth = dayjs().subtract(1, "month").endOf("month").toDate();

const ReportCard = ({ title, description, onClick, onSchedule, children }) => (
  <Card shadow="sm" p="lg" radius="md" maw={500} style={{ display: "flex", flexDirection: "column" }} withBorder>
    <Text align="center" weight={700}>
      {title}
    </Text>

    <Text align="center" size="sm" color="dimmed" style={{ flexGrow: 0 }}>
      {description}
    </Text>

    <Stack align="center" spacing="xs">
      {children}
    </Stack>

    <Group position="center">
      <Button onClick={() => onClick("pdf")} color="green" mt="md" radius="md">
        Run
      </Button>
      <Button onClick={() => onClick("csv")} color="green" mt="md" radius="md">
        Export
      </Button>
      <Button onClick={onSchedule} color="green" mt="md" radius="md">
        Schedule
      </Button>
    </Group>
  </Card>
);

const ReportDateParameter = ({ label, value, w, onChange }) => {
  const isPhone = useIsPhone();

  return (
    <DatePickerInput
      label={label}
      valueFormat="MM/DD/YY"
      firstDayOfWeek={0}
      clearable={false}
      dropdownType={isPhone ? "modal" : "popover"}
      popoverProps={{ withinPortal: true }}
      modalProps={{ withinPortal: true }}
      w={w || "120px"}
      value={value}
      onChange={onChange}
      styles={{
        root: { textAlign: "center" },
        input: { textAlign: "center" },
      }}
    />
  );
};

const QuickDateSetter = ({ fieldName, setterFn }) => (
  <Group spacing="xs" position="center">
    <TinyButton onClick={() => setterFn(fieldName, yesterday)}>Yesterday</TinyButton>
    <TinyButton onClick={() => setterFn(fieldName, today)}>Today</TinyButton>
  </Group>
);

const QuickDateRangeSetter = ({ startfieldName, endFieldName, setterFn }) => (
  <Group spacing="xs" position="center">
    <TinyButton
      onClick={() => {
        setterFn(startfieldName, yesterday);
        setterFn(endFieldName, yesterday);
      }}
    >
      Yesterday
    </TinyButton>
    <TinyButton
      onClick={() => {
        setterFn(startfieldName, today);
        setterFn(endFieldName, today);
      }}
    >
      Today
    </TinyButton>
    <TinyButton
      onClick={() => {
        setterFn(startfieldName, firstOfLastMonth);
        setterFn(endFieldName, lastOfLastMonth);
      }}
    >
      Last Month
    </TinyButton>
    <TinyButton
      onClick={() => {
        setterFn(startfieldName, firstOfMonth);
        setterFn(endFieldName, lastOfMonth);
      }}
    >
      This Month
    </TinyButton>
  </Group>
);

export { QuickDateRangeSetter, QuickDateSetter, ReportCard, ReportDateParameter };

import { AuthContext } from "@/auth/AuthProvider";
import { useContext } from "react";

const useAuth = () => {
  const authContext = useContext(AuthContext);

  // remap properties manually so intellisense knows about them
  return {
    token: authContext.token,
    tokenLoading: authContext.tokenLoading,
    invalidAuthToken: authContext.invalidAuthToken,
    claims: authContext.claims,
    onTokenExpired: authContext.onTokenExpired,
    onLogin: authContext.onLogin,
    onTokenLogin: authContext.onTokenLogin,
    onImpersonate: authContext.onImpersonate,
    onLogout: authContext.onLogout,
    onForgotPassword: authContext.onForgotPassword,
    onResetPassword: authContext.onResetPassword,
    onChangePassword: authContext.onChangePassword,
  };
};

export default useAuth;

import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import SimpleTable from "@/components/SimpleTable";
import { showErrorToast, showSuccessToast } from "@/fns";
import { BREAKPOINTS, useMediaBreakpoint } from "@/hooks/useBreakpoints";
import { openUserEditModal } from "@/modals/UserEditModal";
import { ActionIcon, Button, Group, LoadingOverlay } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { noop } from "@tanstack/react-table";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

const columnDef = [
  { accessorKey: "name", header: "Name", metadata: { width: 2 } },
  { accessorKey: "email", header: "Email", metadata: { width: 2 } },
  { accessorKey: "phoneNumber", header: "Phone #", metadata: { width: 1 } },
];

const columnsToShow = {
  [BREAKPOINTS.xl]: 3,
  [BREAKPOINTS.lg]: 3,
  [BREAKPOINTS.md]: 2,
  [BREAKPOINTS.sm]: 2,
  [BREAKPOINTS.xs]: 2,
};

const UserListBase = ({ title = "Users", queryKey, queryFn, onAddUser }) => {
  const queryClient = useQueryClient();
  const breakpoint = useMediaBreakpoint();

  const { data, error, isLoading, isError } = useQuery([queryKey], queryFn);

  const handleAddUser = () => {
    openUserEditModal({
      onSave: async (data) => {
        try {
          await onAddUser(data);
          showSuccessToast("User Added");
        } catch (err) {
          showErrorToast("Error adding user", err.message);
        } finally {
          queryClient.invalidateQueries([queryKey]);
        }
      },
    });
  };

  const handleEditUser = (id) => {
    openUserEditModal({
      id,
      onSave: async (data) => {
        try {
          await api.updateUser(id, data);
          showSuccessToast("User Saved");
        } catch (err) {
          showErrorToast("Error saving user", err.message);
        } finally {
          queryClient.invalidateQueries([queryKey]);
        }
      },
    });
  };

  const handleDeleteUser = (id) => {
    openConfirmModal({
      title: "Are you sure you want to delete this user?",
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: noop,
      onConfirm: () => doDeleteUser(id),
    });
  };

  const doDeleteUser = async (id) => {
    try {
      await api.deleteUser(id);
      showSuccessToast("User Deleted");
    } catch (err) {
      showErrorToast("Error deleting user", err.message);
    } finally {
      queryClient.invalidateQueries([queryKey]);
    }
  };

  const columns = [
    ...columnDef.slice(0, columnsToShow[breakpoint]),
    {
      id: "actions",
      cell: ({ row }) => (
        <Group position="right" noWrap>
          <ActionIcon color="green" variant="outline" onClick={() => handleEditUser(row.original.id)}>
            <FaPencilAlt />
          </ActionIcon>
          <ActionIcon color="red" variant="outline" onClick={() => handleDeleteUser(row.original.id)}>
            <FaTrashAlt />
          </ActionIcon>
        </Group>
      ),
      metadata: { width: 1 },
    },
  ];

  if (isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  return (
    <SimpleTable
      title={title}
      data={data}
      columns={columns}
      rightSection={<Button onClick={handleAddUser}>Add a User</Button>}
      initialSort={[{ id: "name", desc: false }]}
    />
  );
};

export default UserListBase;

import * as api from "@/api";
import { numberedCircleSvg } from "@/assets/svgIcons";
import CircledText from "@/components/CircledText";
import DateChooser from "@/components/DateChooser";
import ErrorAlert from "@/components/ErrorAlert";
import HereMap from "@/components/HereMap";
import SimpleTable from "@/components/SimpleTable";
import { formatTime } from "@/fns";
import useIsPhone from "@/hooks/useIsPhone";
import useQuerystring from "@/hooks/useQuerystring";
import { makeInfoBubbleHtml } from "@/mapFns";
import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js";
import { Grid, Group, Loader, LoadingOverlay, ScrollArea, Stack, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

const formatEventType = (row) => {
  if (row.eventName == "SpeedAlert") return "Speeding";
  if (row.eventName == "IdleStart") return "Idling";
  if (row.eventName == "AccelerationAlert") {
    if (row.acceleration > 0) return "Rapid Accel.";
    return "Harsh Braking";
  }
};

const formatEventValue = (row) => {
  if (row.eventName == "SpeedAlert") return `${row.speed} mph`;
  if (row.eventName == "IdleStart") return `${row.idleDuration} min`;
  if (row.eventName == "AccelerationAlert") {
    if (row.acceleration > 0) return `${(row.acceleration * 0.0028).toFixed(2)} g`;
    return `${(row.deceleration * 0.0028).toFixed(2)} g`;
  }
};

const columnDef = [
  {
    accessorKey: "i",
    cell: (props) => <CircledText color="red">{props.row.getValue("i")}</CircledText>,
    header: "",
  },
  {
    accessorKey: "triggerTime",
    cell: (props) => formatTime(dayjs.utc(props.row.getValue("triggerTime"))),
    header: "Time",
  },
  { id: "speed", accessorFn: (row) => formatEventType(row), header: "Type", metadata: { align: "center" } },
  {
    id: "value",
    accessorFn: (row) => formatEventValue(row),
    header: "",
    metadata: { align: "center" },
  },
];

const MapVehicleAlerts = () => {
  const isPhone = useIsPhone();
  const mapRef = useRef();
  const { vehicleId } = useParams();
  const qs = useQuerystring();
  const defaultDate = qs.get("date") ? dayjs(qs.get("date")).toDate() : new Date();

  const [bounds, setBounds] = useState();
  const [date, setDate] = useState(defaultDate);

  const handleDataLoaded = async (data) => {
    if (data.length == 0) return;

    var markerGroup = new H.map.Group();
    mapRef.current.map.getObjects().forEach((o) => mapRef.current.map.removeObject(o));
    mapRef.current.map.addObject(markerGroup);

    markerGroup.addEventListener("tap", (e) => {
      var bubble = new H.ui.InfoBubble(e.target.getGeometry(), {
        content: makeInfoBubbleHtml(e.target.getData()),
      });
      mapRef.current.ui.getBubbles().forEach((b) => mapRef.current.ui.removeBubble(b));
      mapRef.current.ui.addBubble(bubble);
    });

    data.forEach((d) => {
      const m = new H.map.Marker(
        { lat: d.latitude, lng: d.longitude },
        { icon: new H.map.Icon(numberedCircleSvg("red", d.i)) }
      );
      m.setData(d);
      markerGroup.addObject(m);
    });

    setBounds(markerGroup.getBoundingBox());
  };

  const {
    data: vehicle,
    isLoading: vehicleLoading,
    isError: isVehicleError,
    error: vehicleError,
  } = useQuery(["vehicle", vehicleId], () => api.getVehicle(vehicleId));

  const {
    data: alerts,
    error: alertsError,
    isLoading: alertsLoading,
    isError: isAlertsError,
  } = useQuery(
    ["vehicles", vehicleId, dayjs(date).format("YYYY-MM-DD")],
    () =>
      api
        .getVehicleAlerts(vehicleId, dayjs(date).format("YYYY-MM-DD"))
        .then((data) =>
          data.sort((a, b) => (a.triggerTime > b.triggerTime ? 1 : -1)).map((d, i) => ({ ...d, i: i + 1 }))
        ),
    {
      enabled: !!date,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (mapRef.current && alerts) {
      handleDataLoaded(alerts);
    }
  }, [alerts]);

  const handleSelectDate = (d) => setDate(d);

  if (vehicleLoading) return <LoadingOverlay visible />;
  if (isVehicleError) return <ErrorAlert message={vehicleError.message} />;
  if (isAlertsError) return <ErrorAlert message={alertsError.message} />;

  return (
    <Stack spacing={0} h="calc(100vh - 112px)">
      <Group position="apart" mb="sm">
        <div>
          <Title order={3}>Alerts for {vehicle.vehicleName}</Title>
          <Text>{`${vehicle.driverName} - ${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Text>
        </div>
        <DateChooser value={date} onChange={handleSelectDate} />
      </Group>
      {alertsLoading ? (
        <Loader />
      ) : alerts.length == 0 ? (
        <Text>No Alerts for This Date</Text>
      ) : isPhone ? (
        <Stack h="calc(100vh - 115px)">
          <ScrollArea.Autosize type="auto" mah="30vh">
            <SimpleTable data={alerts} columns={columnDef} showPagination={false} />
          </ScrollArea.Autosize>
          <HereMap bounds={bounds} ref={mapRef} />
        </Stack>
      ) : (
        <Grid h="calc(100vh - 115px)">
          <Grid.Col span={3}>
            <SimpleTable data={alerts} columns={columnDef} showPagination={false} />
          </Grid.Col>
          <Grid.Col span={9}>
            <HereMap bounds={bounds} ref={mapRef} />
          </Grid.Col>
        </Grid>
      )}
    </Stack>
  );
};

export default MapVehicleAlerts;

import {
  ActionIcon,
  Button,
  Chip,
  Group,
  NumberInput,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useEffect, useRef } from "react";
import { FaRegClock } from "react-icons/fa";

const relativeDates = ["Yesterday", "Today", "Last Week", "This Week", "Last Month", "This Month"];
const frequencies = ["Daily", "Weekly", "Monthly"];
const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const daysToInts = { SUN: "1", MON: "2", TUE: "3", WED: "4", THU: "5", FRI: "6", SAT: "7" };

const cronToObject = (cron) => {
  let frequency = "",
    weekDays = [],
    dayNumber = "";

  cron = cron.replace("cron(", "").replace(")", "").split(" ");

  const time = cron[1] + ":" + cron[0];
  if (cron[2] == "*") {
    frequency = "daily";
  } else if (cron[2] == "?") {
    frequency = "weekly";
    weekDays = cron[4].split(",").map((d) => daysOfWeek[parseInt(d) - 1]);
  } else {
    frequency = "monthly";
    dayNumber = parseInt(cron[2]);
  }
  return { frequency, weekDays, dayNumber, time };
};

const objectToCron = (obj) => {
  const { frequency, weekDays, dayNumber, time } = obj;
  const res = ["minute", "hour", "*", "*", "?", "*"];
  const [hour, minute] = time.split(":");
  res[0] = minute;
  res[1] = hour;
  if (frequency === "weekly") {
    res[2] = "?";
    res[4] = weekDays
      .map((d) => daysToInts[d.toUpperCase()])
      .sort((a, b) => a - b)
      .join(",");
  } else if (frequency === "monthly") {
    res[2] = dayNumber.toString();
  }
  return res.join(" ");
};

const ReportScheduler = ({ data, onSave, onCancel }) => {
  const ref = useRef();

  const form = useForm({
    initialValues: {
      relativeDate: "Yesterday",
      frequency: "daily",
      weekDays: [],
      dayNumber: "",
      time: "",
      emailTo: "",
    },
    validate: {
      weekDays: (value, values) =>
        values.frequency === "weekly" && value.length === 0 && "Please select at least one day",
      dayNumber: (value, values) => values.frequency === "monthly" && value === "" && "Required",
    },
  });

  useEffect(() => {
    if (data) {
      form.setValues({ ...data, ...cronToObject(data.cronExpression) });
    }
  }, [data]);

  const handleSubmit = (values) =>
    onSave({
      ...values,
      cronExpression: objectToCron(values),
    });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="xs">
        <Title order={6}>Report Date Range</Title>
        <SegmentedControl
          data={relativeDates.map((r) => ({ label: r, value: r.replaceAll(" ", "") }))}
          {...form.getInputProps("relativeDate")}
        />
        <Title order={6}>Repeating</Title>
        <SegmentedControl
          data={frequencies.map((f) => ({ label: f, value: f.toLowerCase() }))}
          {...form.getInputProps("frequency")}
        />
        {form.values.frequency === "weekly" && (
          <>
            {form.errors.weekDays && (
              <Text ta="center" c="red">
                {form.errors.weekDays}
              </Text>
            )}
            <Chip.Group multiple {...form.getInputProps("weekDays")}>
              <Group position="center">
                {daysOfWeek.map((wd) => (
                  <Chip key={wd} value={wd}>
                    {wd}
                  </Chip>
                ))}
              </Group>
            </Chip.Group>
          </>
        )}
        <Group position="center">
          {form.values.frequency === "monthly" && (
            <>
              <Text>On Day</Text>
              <NumberInput type="number" hideControls w={50} {...form.getInputProps("dayNumber")} />
            </>
          )}
          <Text>At</Text>
          <TimeInput
            required
            ref={ref}
            rightSection={
              <ActionIcon onClick={() => ref.current.showPicker()}>
                <FaRegClock size="1rem" />
              </ActionIcon>
            }
            {...form.getInputProps("time")}
          />
        </Group>
        <Title order={6}>Email To</Title>
        <TextInput required placeholder="Email Address" {...form.getInputProps("emailTo")} />
        <Group position="center">
          <Button type="submit">Save</Button>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default ReportScheduler;

import * as api from "@/api";
import { Autocomplete } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useEffect, useState } from "react";

const AddressAutocomplete = ({ onSelect }) => {
  const [query, setQuery] = useState("");
  const [matches, setMatches] = useState([]);

  const [debouncedQuery] = useDebouncedValue(query, 250);

  useEffect(() => {
    if (debouncedQuery) {
      api.geoAutocomplete(debouncedQuery).then((res) => {
        const matches = res?.items?.map((r) => ({ id: r.id, value: r.address.label.replace(", United States", "") }));
        setMatches(matches);
      });
    } else {
      setMatches([]);
    }
  }, [debouncedQuery]);

  const handleSelect = async (item) => {
    const geoResult = await api.geoLookup(item.id);
    onSelect({ address: item.value, location: geoResult.position, bounds: geoResult.mapView });
  };

  return (
    <Autocomplete
      placeholder="Jump to location..."
      value={query}
      onChange={setQuery}
      data={matches}
      filter={() => true} /* show ALL matches, not just exact ones */
      onItemSubmit={handleSelect}
      styles={() => ({
        input: {
          backgroundColor: "white !important",
        },
      })}
    />
  );
};

export default AddressAutocomplete;

import axios from "axios";

// interceptors are attached to this instance (see AxiosInterceptor.jsx)
var api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

// raw axios instance for token-less auth calls (no interceptors)
var authApi = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

const validateToken = async (data) => await authApi.post("auth/validateToken", data).then((res) => res.data);

const refreshToken = async (data) =>
  await authApi
    .post("auth/refreshToken", data)
    .then((res) => res.data)
    .catch(() => ({ accessToken: "", refreshToken: "" })); // return empty token if refresh fails, so subsequent retry will 401 and user will be redirected to login

const changePassword = async (data) => await api.post("auth/changePassword", data);
const forgotPassword = async (email) => await api.get("auth/forgotPassword", { params: { email } });
const login = async (data) => await api.post("auth/login", data);
const tokenLogin = async (token) => await api.get("auth/token", { params: { token } });
const impersonate = async (companyId) => await api.post("auth/impersonate", { impersonate: !!companyId, companyId });
const resetPassword = async (data) => await api.post("auth/resetPassword", data);

const getVehicle = async (id) => await api.get(`vehicles/${id}`);
const getVehicles = async () => await api.get("vehicles");
const updateVehicle = async (id, data) => await api.put(`vehicles/${id}`, data);
const getVehicleAlerts = async (id, date) => await api.get(`vehicles/${id}/alerts/${date}`);
const getVehicleRoute = async (id, date) => await api.get(`vehicles/${id}/route/${date}`);

const deleteVehicle = async (id) => await api.delete(`vehicles/${id}`);

const getVehicleGroup = async (id) => await api.get(`groups/${id}`);
const getVehicleGroups = async () => await api.get("groups");
const getVehicleGroupByName = async (name) => await api.get(`groups/name/${name}`);
const createVehicleGroup = async (data) => await api.post(`groups`, data);
const updateVehicleGroup = async (id, data) => await api.put(`groups/${id}`, data);
const deleteVehicleGroup = async (id) => await api.delete(`groups/${id}`);

const createCompany = async (data) => await api.post("companies", data);
const getCompanies = async () => await api.get("companies");
const getCompany = async (id) => await api.get(`companies/${id}`);
const updateCompany = async (id, data) => await api.put(`companies/${id}`, data);
const deleteCompany = async (id) => await api.delete(`companies/${id}`);

const deleteUser = async (id) => await api.delete(`users/${id}`);
const getUsers = async () => await api.get("users");
const getUser = async (id) => await api.get(`users/${id}`);
const registerUser = async (data) => await api.post("auth/register", data);
const updateUser = async (id, data) => await api.put(`users/${id}`, data);

const adminGetVehicles = async () => await api.get("admin/vehicles");
const adminCreateVehicle = async (data) => await api.post("admin/vehicles", data);
const adminUpdateVehicle = async (id, data) => await api.put(`admin/vehicles/${id}`, data);
const adminDeleteVehicle = async (id) => await api.delete(`admin/vehicles/${id}`);

const adminGetUsers = async () => await api.get("admin/users");

const getAlertSchedule = async (id) => await api.get(`alertSchedules/${id}`);
const getAlertSchedules = async () => await api.get("alertSchedules");
const getAlertScheduleByName = async (name) => await api.get(`alertSchedules/name/${name}`);
const createAlertSchedule = async (data) => await api.post(`alertSchedules`, data);
const updateAlertSchedule = async (id, data) => await api.put(`alertSchedules/${id}`, data);
const deleteAlertSchedule = async (id) => await api.delete(`alertSchedules/${id}`);

const getGeofence = async (id) => await api.get(`geofences/${id}`);
const getGeofences = async () => await api.get("geofences");
const getGeofenceByName = async (name) => await api.get(`geofences/name/${name}`);
const createGeofence = async (data) => await api.post(`geofences`, data);
const updateGeofence = async (id, data) => await api.put(`geofences/${id}`, data);
const deleteGeofence = async (id) => await api.delete(`geofences/${id}`);

const updateAlertRule = async (companyId, data) => await api.put(`companies/${companyId}/alertRules`, data);

const getBluStarTechs = async () => await api.get("bluStar/techs");

const getScheduledReports = async () => await api.get(`${import.meta.env.VITE_REPORTING_BASE_URL}/schedules`);
const createScheduledReport = async (data) =>
  await api.post(`${import.meta.env.VITE_REPORTING_BASE_URL}/schedules`, data);
const updateScheduledReport = async (id, data) =>
  await api.put(`${import.meta.env.VITE_REPORTING_BASE_URL}/schedules/${id}`, data);
const patchScheduledReport = async (id, data) =>
  await api.patch(`${import.meta.env.VITE_REPORTING_BASE_URL}/schedules/${id}`, data);
const deleteScheduledReport = async (id) =>
  await api.delete(`${import.meta.env.VITE_REPORTING_BASE_URL}/schedules/${id}`);

const geoAutocomplete = async (q) =>
  await axios
    .get("https://autocomplete.search.hereapi.com/v1/autocomplete", {
      params: { q, limit: 5, in: "countryCode:USA", apiKey: import.meta.env.VITE_HERE_MAPS_API_KEY },
    })
    .then((res) => res.data);

const geoLookup = async (id) =>
  await axios
    .get("https://autocomplete.search.hereapi.com/v1/lookup", {
      params: { id, apiKey: import.meta.env.VITE_HERE_MAPS_API_KEY },
    })
    .then((res) => res.data);

const geoMatchRoute = async (data) =>
  await axios
    .post("https://fleet.api.here.com/2/calculateroute.json", data, {
      params: {
        routeMatch: 1,
        drivingReport: 1,
        mode: "fastest;car;traffic:disabled",
        apiKey: import.meta.env.VITE_HERE_MAPS_API_KEY,
      },
    })
    .then((res) => res.data);

const runReport = async (reportName, data, fileType = "pdf") => {
  const mimeType =
    fileType == "xls"
      ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      : fileType == "csv"
      ? "text/csv"
      : "application/pdf";

  return api
    .post(
      `${import.meta.env.VITE_REPORTING_BASE_URL}/${reportName}`,
      { ...data, fileType },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: mimeType,
        },
      }
    )
    .then((response) => {
      var file = new Blob([response], {
        type: mimeType,
      });
      return URL.createObjectURL(file);
    });
};

export {
  api as axiosInstance,
  adminCreateVehicle,
  adminDeleteVehicle,
  adminGetVehicles,
  adminUpdateVehicle,
  adminGetUsers,
  changePassword,
  createAlertSchedule,
  createCompany,
  createGeofence,
  createScheduledReport,
  createVehicleGroup,
  deleteAlertSchedule,
  deleteCompany,
  deleteGeofence,
  deleteScheduledReport,
  deleteUser,
  deleteVehicle,
  deleteVehicleGroup,
  forgotPassword,
  geoAutocomplete,
  geoLookup,
  geoMatchRoute,
  getAlertSchedule,
  getAlertScheduleByName,
  getAlertSchedules,
  getBluStarTechs,
  getCompanies,
  getCompany,
  getGeofence,
  getGeofences,
  getGeofenceByName,
  getScheduledReports,
  getUsers,
  getUser,
  getVehicle,
  getVehicles,
  getVehicleAlerts,
  getVehicleGroup,
  getVehicleGroups,
  getVehicleGroupByName,
  getVehicleRoute,
  impersonate,
  login,
  patchScheduledReport,
  refreshToken,
  registerUser,
  resetPassword,
  runReport,
  tokenLogin,
  updateAlertRule,
  updateAlertSchedule,
  updateCompany,
  updateGeofence,
  updateScheduledReport,
  updateUser,
  updateVehicle,
  updateVehicleGroup,
  validateToken,
};

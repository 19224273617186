import CompanyAdd from "@/pages/admin/companies/CompanyAdd";
import { openContextModal } from "@mantine/modals";

const CompanyAddModal = ({ context, id, innerProps }) => {
  const handleSave = (data) => {
    context.closeModal(id);
    innerProps.onSave(data);
  };

  const handleCancel = () => {
    context.closeModal(id);
  };

  return <CompanyAdd onSave={handleSave} onCancel={handleCancel} />;
};

const openCompanyAddModal = (innerProps) => {
  openContextModal({
    modal: "CompanyAddModal",
    size: "md",
    title: "Add a Company",
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    innerProps,
  });
};

export { CompanyAddModal, openCompanyAddModal };

import UserEdit from "@/pages/users/UserEdit";
import { openContextModal } from "@mantine/modals";

const UserEditModal = ({ context, id, innerProps }) => {
  const handleSave = (data) => {
    context.closeModal(id);
    innerProps.onSave(data);
  };

  const handleCancel = () => {
    context.closeModal(id);
  };

  return <UserEdit id={innerProps.id} onSave={handleSave} onCancel={handleCancel} />;
};

const openUserEditModal = (innerProps) => {
  openContextModal({
    modal: "UserEditModal",
    size: "md",
    title: `${innerProps.id ? "Edit" : "Add a"} User`,
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    innerProps,
  });
};

export { UserEditModal, openUserEditModal };

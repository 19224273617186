import AlertScheduleEdit from "@/pages/alerts/AlertScheduleEdit";
import { openContextModal } from "@mantine/modals";

const AlertScheduleEditModal = ({ context, id, innerProps }) => {
  const handleSave = (data) => {
    context.closeModal(id);
    innerProps.onSave(data);
  };

  const handleCancel = () => {
    context.closeModal(id);
  };

  return <AlertScheduleEdit id={innerProps.id} onSave={handleSave} onCancel={handleCancel} />;
};

const openAlertScheduleEditModal = (innerProps) => {
  openContextModal({
    modal: "AlertScheduleEditModal",
    size: "xl",
    title: `${innerProps.id ? "Edit" : "Add an"} Alert Schedule`,
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    innerProps,
  });
};

export { AlertScheduleEditModal, openAlertScheduleEditModal };

import useAuth from "@/hooks/useAuth";
import {
  Alert,
  Button,
  Center,
  Container,
  Group,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const { token, onLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [loginError, setLoginError] = useState();
  const [showLoading, setShowLoading] = useState(false);

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },
  });

  const redirectTo = location.state?.from ? location.state.from.pathname + (location.state.from.search ?? "") : "/";

  useEffect(() => {
    if (token) {
      navigate(redirectTo);
    }
  }, [token]);

  const handleSubmit = async (values) => {
    try {
      setShowLoading(true);
      await onLogin(values);
      navigate(redirectTo);
    } catch (err) {
      setLoginError(err.message);
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <Container size={300} mt={50}>
      {loginError && <Alert color="red" mb={10} title={loginError} styles={{ title: { marginBottom: "0px" } }} />}
      <Paper p="md" shadow="xs">
        <Title order={4}>Log In</Title>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <TextInput
              label="Email Address"
              required
              withAsterisk={false}
              autoFocus
              {...form.getInputProps("username")}
            />
            <PasswordInput label="Password" required withAsterisk={false} {...form.getInputProps("password")} />
            <Group position="center">
              <Button type="submit">Log In</Button>
            </Group>
            <Center>
              <Text size="sm">
                <Link to="/auth/forgotPassword">Forgot Password</Link>
              </Text>
            </Center>
          </Stack>
        </form>
      </Paper>
      <LoadingOverlay visible={showLoading} />
    </Container>
  );
};

export default Login;

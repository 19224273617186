import { ActionIcon, Group } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const DateChooser = ({ value, onChange }) => (
  <Group spacing="xs">
    <ActionIcon variant="outline" onClick={() => onChange(dayjs(value).subtract(1, "day").toDate())}>
      <FaChevronLeft />
    </ActionIcon>
    <DatePickerInput
      valueFormat="ddd MM/DD/YY"
      firstDayOfWeek={0}
      clearable={false}
      w="130px"
      styles={{
        input: {
          textAlign: "center",
          fontSize: "1rem",
          fontWeight: "bold",
          border: "1px solid #868e96",
          backgroundColor: "transparent !important",
        },
      }}
      value={value}
      onChange={onChange}
    />
    <ActionIcon variant="outline" onClick={() => onChange(dayjs(value).add(1, "day").toDate())}>
      <FaChevronRight />
    </ActionIcon>
  </Group>
);

export default DateChooser;

import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js";
import { formatHeading, formatRelativeDateTime, formatTime, formatUtcTimeToLocal } from "./fns";

// https://developer.here.com/documentation/examples/maps-js/resizable-geoshapes/resizable-rect
const createResizableRect = (map, behavior, bounds, onResize) => {
  var rect = new H.map.Rect(new H.geo.Rect(bounds.maxLat, bounds.minLng, bounds.minLat, bounds.maxLng), {
      style: { fillColor: "rgba(100, 100, 100, 0.5)", lineWidth: 0 },
    }),
    rectOutline = new H.map.Polyline(rect.getGeometry().getExterior(), {
      style: { lineWidth: 2, strokeColor: "rgba(0, 0, 0, 0.5)", fillColor: "rgba(0, 0, 0, 0)", lineCap: "square" },
    }),
    rectGroup = new H.map.Group({
      volatility: true, // mark the group as volatile for smooth dragging of all its objects
      objects: [rect, rectOutline],
    }),
    rectTimeout;

  // ensure that the objects can receive drag events
  rect.draggable = true;
  rectOutline.draggable = true;

  // extract first point of the rect's outline polyline's LineString and
  // push it to the end, so the outline has a closed geometry
  rectOutline.getGeometry().pushPoint(rectOutline.getGeometry().extractPoint(0));

  // map.getObjects().forEach((obj) => map.removeObject(obj));

  // add group with rect and its outline (polyline)
  map.addObject(rectGroup);

  // event listener for rectangle group to show outline (polyline) if moved in with mouse (or touched on touch devices)
  rectGroup.addEventListener(
    "pointerenter",
    function () {
      var currentStyle = rectOutline.getStyle(),
        newStyle = currentStyle.getCopy({
          strokeColor: "rgba(0, 0, 0, 0.5)",
        });

      if (rectTimeout) {
        clearTimeout(rectTimeout);
        rectTimeout = null;
      }
      // show outline
      rectOutline.setStyle(newStyle);
    },
    true
  );

  // event listener for rectangle group to hide outline if moved out with mouse (or released finger on touch devices)
  // the outline is hidden on touch devices after specific timeout
  rectGroup.addEventListener(
    "pointerleave",
    function (evt) {
      var currentStyle = rectOutline.getStyle(),
        newStyle = currentStyle.getCopy({
          strokeColor: "rgba(0, 0, 0, 0.5)",
        }),
        timeout = evt.currentPointer.type == "touch" ? 1000 : 0;

      rectTimeout = setTimeout(function () {
        rectOutline.setStyle(newStyle);
      }, timeout);

      document.body.style.cursor = "default";
    },
    true
  );

  // event listener for rectangle group to change the cursor if mouse position is over the outline polyline (resizing is allowed)
  rectGroup.addEventListener(
    "pointermove",
    function (evt) {
      var pointer = evt.currentPointer,
        objectTopLeftScreen = map.geoToScreen(evt.target.getGeometry().getBoundingBox().getTopLeft()),
        objectBottomRightScreen = map.geoToScreen(evt.target.getGeometry().getBoundingBox().getBottomRight()),
        draggingType = "";

      // only set cursor and draggingType if target is outline polyline
      if (evt.target != rectOutline) {
        return;
      }

      // change document cursor depending on the mouse position
      if (pointer.viewportX < objectTopLeftScreen.x + 4) {
        document.body.style.cursor = "ew-resize"; // mouse position is at left side
        draggingType = "left";
      } else if (pointer.viewportX > objectBottomRightScreen.x - 4) {
        document.body.style.cursor = "ew-resize"; // mouse position is at right side
        draggingType = "right";
      } else if (pointer.viewportY < objectTopLeftScreen.y + 4) {
        document.body.style.cursor = "ns-resize"; // mouse position is at top side
        draggingType = "top";
      } else if (pointer.viewportY > objectBottomRightScreen.y - 4) {
        document.body.style.cursor = "ns-resize"; // mouse position is at the bottom side
        draggingType = "bottom";
      } else {
        document.body.style.cursor = "default";
      }

      if (draggingType == "left") {
        if (pointer.viewportY < objectTopLeftScreen.y + 4) {
          document.body.style.cursor = "nwse-resize"; // mouse position is at the top-left corner
          draggingType = "left-top";
        } else if (pointer.viewportY > objectBottomRightScreen.y - 4) {
          document.body.style.cursor = "nesw-resize"; // mouse position is at the bottom-left corner
          draggingType = "left-bottom";
        }
      } else if (draggingType == "right") {
        if (pointer.viewportY < objectTopLeftScreen.y + 4) {
          document.body.style.cursor = "nesw-resize"; // mouse position is at the top-right corner
          draggingType = "right-top";
        } else if (pointer.viewportY > objectBottomRightScreen.y - 4) {
          document.body.style.cursor = "nwse-resize"; // mouse position is at the bottom-right corner
          draggingType = "right-bottom";
        }
      }

      rectGroup.setData({ draggingType: draggingType });
    },
    true
  );

  // disable the map's behavior if resizing started so map doesn't pan in the situation
  // when we try to set rect size to 0 or negative and mouse cursor leaves the map object
  rectGroup.addEventListener(
    "dragstart",
    function (evt) {
      if (evt.target === rectOutline) {
        behavior.disable();
      }
    },
    true
  );

  // event listener for rect group to resize the geo rect object if dragging over outline polyline
  rectGroup.addEventListener(
    "drag",
    function (evt) {
      var pointer = evt.currentPointer,
        pointerGeoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY),
        currentGeoRect = rect.getGeometry().getBoundingBox();
      //var objectTopLeftScreen = map.geoToScreen(currentGeoRect.getTopLeft());
      //var objectBottomRightScreen = map.geoToScreen(currentGeoRect.getBottomRight());

      // if pointer is over outline, resize the geo rect object
      if (evt.target instanceof H.map.Polyline) {
        var currentTopLeft = currentGeoRect.getTopLeft(),
          currentBottomRight = currentGeoRect.getBottomRight(),
          newGeoRect,
          outlineLinestring;

        // update rect's size depending on dragging type:
        switch (rectGroup.getData()["draggingType"]) {
          case "left-top":
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng >= currentBottomRight.lng || pointerGeoPoint.lat <= currentBottomRight.lat) {
              return;
            }
            newGeoRect = H.geo.Rect.fromPoints(pointerGeoPoint, currentGeoRect.getBottomRight());
            break;
          case "left-bottom":
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng >= currentBottomRight.lng || pointerGeoPoint.lat >= currentTopLeft.lat) {
              return;
            }
            currentTopLeft.lng = pointerGeoPoint.lng;
            currentBottomRight.lat = pointerGeoPoint.lat;
            newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentBottomRight);
            break;
          case "right-top":
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng <= currentTopLeft.lng || pointerGeoPoint.lat <= currentBottomRight.lat) {
              return;
            }
            currentTopLeft.lat = pointerGeoPoint.lat;
            currentBottomRight.lng = pointerGeoPoint.lng;
            newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentBottomRight);
            break;
          case "right-bottom":
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng <= currentTopLeft.lng || pointerGeoPoint.lat >= currentTopLeft.lat) {
              return;
            }
            newGeoRect = H.geo.Rect.fromPoints(currentGeoRect.getTopLeft(), pointerGeoPoint);
            break;
          case "left":
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng >= currentBottomRight.lng) {
              return;
            }
            currentTopLeft.lng = pointerGeoPoint.lng;
            newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentGeoRect.getBottomRight());
            break;
          case "right":
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng <= currentTopLeft.lng) {
              return;
            }
            currentBottomRight.lng = pointerGeoPoint.lng;
            newGeoRect = H.geo.Rect.fromPoints(currentGeoRect.getTopLeft(), currentBottomRight);
            break;
          case "top":
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lat <= currentBottomRight.lat) {
              return;
            }
            currentTopLeft.lat = pointerGeoPoint.lat;
            newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentGeoRect.getBottomRight());
            break;
          case "bottom":
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lat >= currentTopLeft.lat) {
              return;
            }
            currentBottomRight.lat = pointerGeoPoint.lat;
            newGeoRect = H.geo.Rect.fromPoints(currentGeoRect.getTopLeft(), currentBottomRight);
            break;
        }

        // set the new bounding box for rect object
        rect.setBoundingBox(newGeoRect);

        // extract first point of the outline LineString and push it to the end, so the outline has a closed geometry
        outlineLinestring = rect.getGeometry().getExterior();
        outlineLinestring.pushPoint(outlineLinestring.extractPoint(0));
        rectOutline.setGeometry(outlineLinestring);

        // prevent event from bubling, so map doesn't receive this event and doesn't pan
        evt.stopPropagation();
      }
    },
    true
  );

  // event listener for rect group to enable map's behavior
  rectGroup.addEventListener(
    "dragend",
    function () {
      if (onResize) onResize(rect.getBoundingBox());
      behavior.enable();
    },
    true
  );

  return rectGroup;
};

const addRouteLinksToObjectContainer = (routeLinks, highlight = false) => {
  const objContainer = new H.map.Group();

  // create two polylines per segment, one with a wider stroke
  // and darker color to create an outline effect

  const lineStyle = {
    lineWidth: 6,
    strokeColor: highlight ? "#00ff00" : "#00b0ff",
  };

  const lineStyle2 = {
    lineWidth: 10,
    strokeColor: highlight ? "#008000" : "#1967D2",
  };

  for (var l = 0; l < routeLinks.length; l++) {
    var linkShape = routeLinks[l].shape;
    var coords = new H.geo.LineString();

    for (var c = 0; c < linkShape.length; c += 2) {
      coords.pushLatLngAlt(linkShape[c], linkShape[c + 1], null);
    }

    var linkPolyline = new H.map.Polyline(coords, { zIndex: 4, style: lineStyle });
    linkPolyline.setData(routeLinks[l]);
    objContainer.addObject(linkPolyline);

    var linkPolyline2 = new H.map.Polyline(coords, { zIndex: 3, style: lineStyle2 });
    objContainer.addObject(linkPolyline2);
  }

  return objContainer;
};

const reverseGeocode = async (lat, lng) => {
  const url = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${lat}%2C${lng}&lang=en-US&apiKey=${
    import.meta.env.VITE_HERE_MAPS_API_KEY
  }`;

  const res = await fetch(url);

  if (!res.ok) {
    return { geocodeQuality: "not_found" };
  }

  const data = await res.json();

  if (data.items && data.items.length > 0) {
    const item = data.items[0];
    const { houseNumber, street, city, stateCode, postalCode } = item.address;

    return {
      streetAddress: item.resultType == "place" ? item.title : houseNumber ? `${houseNumber} ${street}` : street,
      city,
      state: stateCode,
      zip: postalCode,
      geocodeQuality: item.resultType,
    };
  }
};

const makeInfoBubbleHtml = (waypoint) => {
  if (waypoint.segmentType == "Stopped") {
    let str = `<div style="white-space: nowrap">Stopped `;
    if (waypoint.boundaryType == "FirstEvent") {
      str += ` until ${formatTime(waypoint.endTimeLocal)}`;
    } else if (waypoint.boundaryType == "LastEvent") {
      str += ` at ${formatTime(waypoint.startTimeLocal)}`;
    } else {
      str += `${formatTime(waypoint.startTimeLocal)}-${formatTime(waypoint.endTimeLocal)}`;
    }
    if (waypoint.streetAddress) {
      str += `<br />${waypoint.streetAddress}<br />${waypoint.city}, ${waypoint.state} ${waypoint.zip} `;
    }
    return str + "</div>";
  } else {
    return `
      <div style="white-space: nowrap">
        ${formatUtcTimeToLocal(waypoint.triggerTime)}
        <br />
        ${waypoint.speed.toFixed(0)} mph
        <br />
        Heading ${formatHeading(waypoint.heading)}
      </div>
    `;
  }
};

const makeVehicleInfoBubbleHtml = async (v) => {
  if (v.lastPoint?.latitude && !v.lastPoint?.streetAddress) {
    const address = await reverseGeocode(v.lastPoint.latitude, v.lastPoint.longitude);
    v.lastPoint = { ...v.lastPoint, ...address };
  }

  const statusString = v.status == "Unknown" ? v.status : `${v.status} since ${formatRelativeDateTime(v.statusDate)}`;
  const headingString =
    v.status == "Moving"
      ? `<b>Heading:</b> ${formatHeading(v.lastPoint.heading)} at ${v.lastPoint.speed.toFixed(0)} mph<br />`
      : "";

  return `
      <div style="white-space: nowrap; line-height: 1.35em">
        <b>${v.vehicleName}</b>
        <br />
        ${v.year} ${v.make} ${v.model}
        ${v.driverName ? `<br /><b>Driver:</b> ${v.driverName}` : ""}
        <br />
        <b>Last Contact:</b> ${formatRelativeDateTime(v.lastPoint.triggerTime)}
        <br />
        <b>At:</b> ${v.lastPoint.streetAddress}, ${v.lastPoint.city}, ${v.lastPoint.state}
        <br />
        <b>Status:</b> ${statusString}
        <br />
        ${headingString}
        <a href="/#/map/vehicle/${v.vehicleId}">View Route</a>
      </div>
    `;
};

export {
  addRouteLinksToObjectContainer,
  createResizableRect,
  makeInfoBubbleHtml,
  makeVehicleInfoBubbleHtml,
  reverseGeocode,
};

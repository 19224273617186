import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import SimpleTable from "@/components/SimpleTable";
import { showErrorToast, showSuccessToast } from "@/fns";
import { openGeofenceEditModal } from "@/modals/GeofenceEditModal";
import { ActionIcon, Button, Group, LoadingOverlay, Switch, Text, Tooltip } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { noop } from "@tanstack/react-table";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

const formatRecipients = (row) => {
  const email = row.alertEmails.replaceAll(",", ";").split(";");
  const sms = row.alertSms.replaceAll(",", ";").split(";");
  const res = [...email, ...sms].filter((x) => !!x);
  if (row.emailDriver) res.push("Driver (email)");
  if (row.textDriver) res.push("Driver (text)");
  return res.join(", ");
};

const columnDef = [
  { accessorKey: "name", header: "Name", sortingFn: "text", metadata: { width: 3 } },
  {
    accessorKey: "active",
    header: "Active",
    cell: ({ cell }) => <Switch checked={cell.getValue()} />,
    metadata: { width: 1 },
  },
  {
    accessorKey: "allVehicles",
    header: "All Vehicles",
    cell: ({ cell }) => <Switch checked={cell.getValue()} />,
    metadata: { width: 1 },
  },
  { id: "recipients", accessorFn: formatRecipients, header: "Recipients", metadata: { width: 4 } },
];

const GeofenceList = () => {
  const queryClient = useQueryClient();

  const { data, error, isLoading, isError } = useQuery(["geofences"], api.getGeofences);

  const handleAddGeofence = () => {
    openGeofenceEditModal({ id: null, onSave: (data) => handleSaveGeofence(null, "create", data) });
  };

  const handleEditGeofence = (geofenceId) => {
    openGeofenceEditModal({ id: geofenceId, onSave: (data) => handleSaveGeofence(geofenceId, "update", data) });
  };

  const handleSaveGeofence = async (geofenceId, mode, data) => {
    try {
      if (mode === "create") {
        await api.createGeofence(data);
      } else {
        await api.updateGeofence(geofenceId, data);
      }
      showSuccessToast("Saved");
    } catch (err) {
      showErrorToast("Save Failed", err.message);
    } finally {
      queryClient.invalidateQueries(["geofences"]);
    }
  };

  const handleDeleteGeofence = async (geofenceId) => {
    openConfirmModal({
      title: "Are you sure you want to delete this geofence?",
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: noop,
      onConfirm: () => doDeleteGeofence(geofenceId),
    });
  };

  const doDeleteGeofence = async (id) => {
    try {
      await api.deleteGeofence(id);
    } catch (err) {
      showErrorToast("Error deleting geofence", err.message);
    } finally {
      queryClient.invalidateQueries(["geofences"]);
    }
  };

  if (isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  const columns = [
    ...columnDef,
    {
      id: "actions",
      cell: ({ row }) => (
        <Group position="right" spacing="xs" noWrap>
          <Tooltip label="Edit Geofence">
            <ActionIcon color="green" variant="outline" onClick={() => handleEditGeofence(row.original.geofenceId)}>
              <FaPencilAlt />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Delete Geofence">
            <ActionIcon color="red" variant="outline" onClick={() => handleDeleteGeofence(row.original.geofenceId)}>
              <FaTrashAlt />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
      metadata: { align: "right" },
    },
  ];

  return (
    <>
      <SimpleTable
        data={data}
        columns={columns}
        title="Geofences"
        rightSection={<Button onClick={handleAddGeofence}>Add a Geofence</Button>}
        initialSort={[{ id: "name", desc: false }]}
      />
      {data.length == 0 && (
        <Text italic align="center">
          No geofences found
        </Text>
      )}
    </>
  );
};

export default GeofenceList;

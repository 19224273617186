import * as api from "@/api";
import UserListBase from "@/components/UserListBase";
import { Button, LoadingOverlay } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";

const CompanyUserList = () => {
  const { companyId } = useParams();

  const { data: companies, isLoading } = useQuery(["companies"], api.getCompanies, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  if (isLoading) return <LoadingOverlay visible />;

  const handleAddUser = async (data) => {
    await api.registerUser({ ...data, companyId });
  };

  return (
    <>
      <Button component={Link} to="/admin/companies" variant="outline" mb="sm" leftIcon={<FaArrowLeft />}>
        Back to Company List
      </Button>
      <UserListBase
        title={`Users for ${companies.find((c) => c.companyId == companyId).name}`}
        queryKey={`users-${companyId}`}
        queryFn={() => api.adminGetUsers().then((res) => res.filter((u) => u.companyId == companyId))}
        onAddUser={handleAddUser}
      />
    </>
  );
};

export default CompanyUserList;

import * as api from "@/api";
import checkeredFlag from "@/assets/checkeredFlag.png";
import greenFlag from "@/assets/greenFlag.png";
import { stopSignSvg, triangleSvgIcon } from "@/assets/svgIcons";
import DateChooser from "@/components/DateChooser";
import ErrorAlert from "@/components/ErrorAlert";
import HereMap from "@/components/HereMap";
import { EVENTS } from "@/fns";
import useQuerystring from "@/hooks/useQuerystring";
import { addRouteLinksToObjectContainer, makeInfoBubbleHtml } from "@/mapFns";
import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js";
import { Group, Loader, LoadingOverlay, Stack, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import MapTimeline from "./MapTimeline";

const greenFlagIcon = new H.map.Icon(greenFlag, { size: { h: 36, w: 36 }, anchor: { x: 12, y: 36 } });
const checkeredFlagIcon = new H.map.Icon(checkeredFlag, { size: { h: 36, w: 36 }, anchor: { x: 12, y: 36 } });
const stopSignIcon = new H.map.Icon(stopSignSvg);

const MapVehicleRoute = () => {
  const mapRef = useRef();
  const { vehicleId } = useParams();
  const qs = useQuerystring();
  const defaultDate = qs.get("date") ? dayjs(qs.get("date")).toDate() : new Date();

  const [bounds, setBounds] = useState();
  const [fullBounds, setFullBounds] = useState();
  const [date, setDate] = useState(defaultDate);
  const [selectedTime, setSelectedTime] = useState();
  const [highlightObject, setHighlightObject] = useState();

  const handleDataLoaded = async (data) => {
    if (data.length == 0) return;

    const { route, datapoints, segments } = data;

    const linkGroup = addRouteLinksToObjectContainer(route.leg[0].link);
    mapRef.current.map.addObject(linkGroup);

    var markerGroup = new H.map.Group();
    mapRef.current.map.addObject(markerGroup);

    markerGroup.addEventListener("tap", (e) => {
      var bubble = new H.ui.InfoBubble(e.target.getGeometry(), {
        content: makeInfoBubbleHtml(e.target.getData()),
      });
      mapRef.current.ui.getBubbles().forEach((b) => mapRef.current.ui.removeBubble(b));
      mapRef.current.ui.addBubble(bubble);
      setSelectedTime(
        e.target.getData().timestamp
          ? dayjs.utc(e.target.getData().timestamp).local()
          : dayjs.utc(e.target.getData().triggerTime).local()
      );
    });

    datapoints.forEach((d) => {
      const m = new H.map.Marker(
        { lat: d.latitude, lng: d.longitude },
        { icon: triangleSvgIcon(d.speed == 0 ? "#ff0000" : "#008000", d.heading) }
      );
      m.setData(d);
      markerGroup.addObject(m);
    });

    segments
      .filter((s) => s.segmentType == "Stopped")
      .forEach((s) => {
        const m = new H.map.Marker({ lat: s.latitude, lng: s.longitude }, { icon: stopSignIcon });
        m.setData(s);
        markerGroup.addObject(m);
      });

    setFullBounds(linkGroup.getBoundingBox());
    setBounds(linkGroup.getBoundingBox());
  };

  const {
    data: vehicle,
    isLoading: vehicleLoading,
    isError: isVehicleError,
    error: vehicleError,
  } = useQuery(["vehicle", vehicleId], () => api.getVehicle(vehicleId));

  const { data, error, isLoading, isError } = useQuery(
    ["vehicles", vehicleId, dayjs(date).format("YYYY-MM-DD")],
    () => api.getVehicleRoute(vehicleId, dayjs(date).format("YYYY-MM-DD")),
    {
      enabled: !!date,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (mapRef.current && data) {
      handleDataLoaded(data);
    }
  }, [data]);

  const handleSelectDate = (d) => {
    setDate(d);
    setHighlightObject();
  };

  const handleSelectSegment = (i, status) => {
    if (highlightObject) {
      mapRef.current.map.removeObject(highlightObject);
    }

    // clear selected time
    setSelectedTime();

    if (i == null || i == undefined) {
      setBounds(fullBounds);
      setHighlightObject();
    } else {
      const routedWaypoints = data.datapoints.filter((p) =>
        [EVENTS.IGNITION_ON, EVENTS.IGNITION_OFF, EVENTS.PERIODIC_IGNITION_ON].includes(p.event)
      );

      const startIdx =
        routedWaypoints.findIndex((d) => dayjs.utc(d.triggerTime) >= dayjs(data.segments[i].startTimeLocal)) || 0;

      let endIdx = routedWaypoints.findIndex((d) => dayjs.utc(d.triggerTime) > dayjs(data.segments[i].endTimeLocal));

      // handle open-ended (first/last) segments
      if (endIdx == -1) {
        if (i == 0) {
          // first segment = stopped, ended by the first ignition-on event
          endIdx = 0;
        } else {
          // last segment = driving, not ended yet, plot remainder of route
          endIdx = routedWaypoints.length - 1;
        }
      }

      const pts = routedWaypoints.slice(startIdx, endIdx);
      const pt0 = routedWaypoints[startIdx];

      const _bounds = { minLat: pt0.latitude, minLng: pt0.longitude, maxLat: pt0.latitude, maxLng: pt0.longitude };
      pts.forEach((p) => {
        if (_bounds.minLat === 0 || p.latitude < _bounds.minLat) _bounds.minLat = p.latitude;
        if (_bounds.minLng === 0 || p.longitude < _bounds.minLng) _bounds.minLng = p.longitude;
        if (_bounds.maxLat === 0 || p.latitude > _bounds.maxLat) _bounds.maxLat = p.latitude;
        if (_bounds.maxLng === 0 || p.longitude > _bounds.maxLng) _bounds.maxLng = p.longitude;
      });
      setBounds(_bounds);

      // assume no route to highlight; later set to non-null if we find a non-empty route below
      setHighlightObject();

      if (status == "Driving") {
        const startLinkIdx = data.route.waypoint[startIdx].routeLinkSeqNrMatched;
        const endLinkIdx = data.route.waypoint[endIdx].routeLinkSeqNrMatched;

        if (startLinkIdx != endLinkIdx) {
          const links = data.route.leg[0].link.slice(startLinkIdx, endLinkIdx);

          const group = addRouteLinksToObjectContainer(links, true);

          group.addObject(new H.map.Marker({ lat: pts[0].latitude, lng: pts[0].longitude }, { icon: greenFlagIcon }));

          group.addObject(
            new H.map.Marker(
              { lat: pts[pts.length - 1].latitude, lng: pts[pts.length - 1].longitude },
              { icon: checkeredFlagIcon }
            )
          );

          const groupObject = mapRef.current.map.addObject(group);
          setHighlightObject(groupObject);
        }
      }
    }
  };

  if (vehicleLoading) return <LoadingOverlay visible />;
  if (isVehicleError) return <ErrorAlert message={vehicleError.message} />;
  if (isError) return <ErrorAlert message={error.message} />;

  return (
    <Stack spacing={0} h="calc(100vh - 112px)">
      <Group position="apart" mb="sm">
        <div>
          <Title order={3}>{vehicle.vehicleName}</Title>
          <Text>{`${vehicle.driverName} - ${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Text>
        </div>
        <DateChooser value={date} onChange={handleSelectDate} />
      </Group>
      {isLoading ? (
        <Loader />
      ) : data.length == 0 ? (
        <Text>No Data for This Date</Text>
      ) : (
        <>
          <HereMap bounds={bounds} ref={mapRef} />
          <MapTimeline segments={data.segments} selectedTime={selectedTime} onSelectSegment={handleSelectSegment} />
        </>
      )}
    </Stack>
  );
};

export default MapVehicleRoute;

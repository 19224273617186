import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import PhoneInput from "@/components/PhoneInput";
import { eventMap, formatDate, formatIsoDate, formatUtcTimeToLocal } from "@/fns";
import { reverseGeocode } from "@/mapFns";
import { Button, Group, LoadingOverlay, NumberInput, Stack, Text, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

const formatLastPoint = (lastPoint, address) => {
  let pos = `At ${address.streetAddress}, ${address.city}, ${address.state}. `;
  pos += `Last Event: ${eventMap[lastPoint.eventName]}`;
  if (formatIsoDate(lastPoint.triggerTime) == formatIsoDate(new Date())) {
    pos += ` at ${formatUtcTimeToLocal(lastPoint.triggerTime)}`;
  } else {
    pos += ` on ${formatDate(lastPoint.triggerTime)}`;
  }

  return pos;
};

const VehicleEdit = ({ vehicleId, onSave, onCancel }) => {
  const [address, setAddress] = useState();

  const form = useForm({
    initialValues: {
      vehicleName: "",
      year: "",
      make: "",
      model: "",
      miles: 0,
      driverEmployeeId: "",
      driverName: "",
      driverPhoneNumber: "",
      devicePhoneNumber: "",
      meId: "",
      uid: "",
    },
  });

  const {
    data: vehicle,
    error,
    isLoading,
    isError,
  } = useQuery(["vehicles", vehicleId], () => api.getVehicle(vehicleId), {
    onSuccess: (data) => form.setValues({ ...data }),
  });

  useEffect(() => {
    if (vehicle?.lastPoint?.streetAddress) {
      setAddress(vehicle.lastPoint);
    } else if (vehicle?.lastPoint?.latitude) {
      reverseGeocode(vehicle.lastPoint.latitude, vehicle.lastPoint.longitude).then((address) => setAddress(address));
    }
  }, [vehicle]);

  const handleSubmit = (values) => {
    onSave(values);
  };

  if (isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="lg">
        <TextInput data-autofocus autoFocus label="Vehicle Name" {...form.getInputProps("vehicleName")} />
        <Text size="sm" italic={!address}>
          {address ? formatLastPoint(vehicle.lastPoint, address) : "Finding Vehicle..."}
        </Text>

        <Group position="apart" spacing="xl" align="flex-start" grow>
          <Stack spacing="xs">
            <Title order={5}>Vehicle Info</Title>
            <TextInput label="Year" {...form.getInputProps("year")} />
            <TextInput label="Make" {...form.getInputProps("make")} />
            <TextInput label="Model" {...form.getInputProps("model")} />
            <NumberInput required hideControls label="Mileage" {...form.getInputProps("miles")} />
          </Stack>

          <Stack spacing="xs">
            <Title order={5}>Driver Info</Title>
            <TextInput label="Driver Employee ID" {...form.getInputProps("driverEmployeeId")} />
            <TextInput label="Driver Name" {...form.getInputProps("driverName")} />
            <PhoneInput label="Driver Phone" {...form.getInputProps("driverPhoneNumber")} />
          </Stack>

          <Stack spacing="xs">
            <Title order={5}>Device Info</Title>
            <TextInput disabled label="Device Phone Number" {...form.getInputProps("devicePhoneNumber")} />
            <TextInput disabled label="MEID" {...form.getInputProps("meId")} />
            <TextInput disabled label="UID" {...form.getInputProps("uid")} />
          </Stack>
        </Group>

        <Group position="center" mt="xl">
          <Button type="submit">Save</Button>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default VehicleEdit;

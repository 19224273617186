import logo from "@/assets/logo.png";
import { isInRole } from "@/fns";
import useAppState from "@/hooks/useAppState";
import useAuth from "@/hooks/useAuth";
import useIsPhone from "@/hooks/useIsPhone";
import { Burger, Button, Group, Header as MantineHeader, Menu } from "@mantine/core";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ConfigLinks, MenuLinks } from "./MenuLinks";

const StyledMenu = ({ children, ...rest }) => (
  <Menu
    {...rest}
    styles={(theme) => ({
      dropdown: {
        backgroundColor: theme.colors.gray[7],
        border: "white 2px solid",
      },
      item: {
        color: "white",
        "&:hover": {
          backgroundColor: theme.colors.green[6],
        },
      },
    })}
  >
    {children}
  </Menu>
);

const Header = () => {
  const { token, claims, onLogout, onImpersonate } = useAuth();
  const { impersonation, stopImpersonating } = useAppState();
  const navigate = useNavigate();
  var isPhone = useIsPhone();

  const showAdmin = isInRole(claims, "Admin");

  const handleLogout = () => {
    onLogout();
    navigate("/auth/login");
  };

  const handleStopImpersonating = async () => {
    await onImpersonate();
    stopImpersonating();
    navigate("/");
  };

  return (
    <MantineHeader
      height={80}
      p="xs"
      styles={(theme) => ({
        root: {
          backgroundColor: theme.colors.gray[7],
          borderBottomWidth: "0px",
        },
      })}
    >
      <Group position="apart" mr={isPhone ? 0 : 20}>
        <Group>
          {isPhone && (
            <StyledMenu position="bottom-start">
              <Menu.Target>
                <Burger color="white" />
              </Menu.Target>
              <Menu.Dropdown>
                {MenuLinks.map((l, i) => (
                  <Menu.Item key={i} onClick={() => navigate(l.route)}>
                    {l.label}
                  </Menu.Item>
                ))}
                {showAdmin && <Menu.Divider />}
                {showAdmin && <Menu.Label>Configuration</Menu.Label>}
                {showAdmin &&
                  ConfigLinks.map((l, i) => (
                    <Menu.Item key={i} onClick={() => navigate(l.route)}>
                      {l.label}
                    </Menu.Item>
                  ))}
                <Menu.Divider />
                <Menu.Item onClick={() => navigate("/auth/changePassword")}>Change Password</Menu.Item>
                <Menu.Item onClick={onLogout}>Log Out</Menu.Item>
              </Menu.Dropdown>
            </StyledMenu>
          )}
          <div>
            <img src={logo} height={60} />
          </div>
        </Group>
        {token && !isPhone && (
          <StyledMenu position="bottom-end">
            <Menu.Target>
              <Button rightIcon={<FaChevronDown />}>
                {claims.name ?? claims.unique_name}
                {impersonation?.impersonating && " *"}
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              {impersonation?.impersonating && (
                <>
                  <Menu.Label>Impersonating {impersonation.name}</Menu.Label>
                  <Menu.Item onClick={handleStopImpersonating}>Stop Impersonating</Menu.Item>
                </>
              )}
              <Menu.Item onClick={() => navigate("/auth/changePassword")}>Change Password</Menu.Item>
              <Menu.Item onClick={handleLogout}>Log Out</Menu.Item>
            </Menu.Dropdown>
          </StyledMenu>
        )}
      </Group>
    </MantineHeader>
  );
};

export default Header;

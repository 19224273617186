import * as api from "@/api";
import SimpleTable from "@/components/SimpleTable";
import { showErrorToast, showSuccessToast } from "@/fns";
import { openAlertScheduleEditModal } from "@/modals/AlertScheduleEditModal";
import { ActionIcon, Button, Group, Text, Tooltip } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQueryClient } from "@tanstack/react-query";
import { noop } from "@tanstack/react-table";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

const columnDef = [{ accessorKey: "name", header: "Name", sortingFn: "text", metadata: { width: 1 } }];

const AlertScheduleList = ({ alertSchedules }) => {
  const queryClient = useQueryClient();

  const handleAddSchedule = () => {
    openAlertScheduleEditModal({ id: null, onSave: (data) => handleSaveSchedule(null, "create", data) });
  };

  const handleEditSchedule = (scheduleId) => {
    openAlertScheduleEditModal({ id: scheduleId, onSave: (data) => handleSaveSchedule(scheduleId, "update", data) });
  };

  const handleSaveSchedule = async (scheduleId, mode, data) => {
    try {
      if (mode === "create") {
        await api.createAlertSchedule(data);
      } else {
        await api.updateAlertSchedule(scheduleId, data);
      }
      showSuccessToast("Saved");
    } catch (err) {
      showErrorToast("Save Failed", err.message);
    } finally {
      queryClient.invalidateQueries(["alertSchedules"]);
    }
  };

  const handleDeleteSchedule = async (scheduleId) => {
    openConfirmModal({
      title: "Are you sure you want to delete this schedule?",
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: noop,
      onConfirm: () => doDeleteSchedule(scheduleId),
    });
  };

  const doDeleteSchedule = async (id) => {
    try {
      await api.deleteAlertSchedule(id);
    } catch (err) {
      showErrorToast("Error deleting alert schedule", err.message);
    } finally {
      queryClient.invalidateQueries(["alertSchedules"]);
    }
  };

  const columns = [
    ...columnDef,
    {
      id: "actions",
      cell: ({ row }) => (
        <Group position="right">
          <Tooltip label="Edit Schedule">
            <ActionIcon color="green" variant="outline" onClick={() => handleEditSchedule(row.original.scheduleId)}>
              <FaPencilAlt />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Delete Schedule">
            <ActionIcon color="red" variant="outline" onClick={() => handleDeleteSchedule(row.original.scheduleId)}>
              <FaTrashAlt />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
      metadata: { align: "right" },
    },
  ];

  return (
    <>
      <SimpleTable
        data={alertSchedules}
        columns={columns}
        title="Alert Schedules"
        rightSection={<Button onClick={handleAddSchedule}>Add an Alert Schedule</Button>}
        initialSort={[{ id: "name", desc: false }]}
        showPagination={false}
      />
      {alertSchedules.length == 0 && (
        <Text italic align="center">
          No schedules found
        </Text>
      )}
    </>
  );
};

export default AlertScheduleList;

import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import tz from "@/tz";
import { Button, Group, LoadingOverlay, Select, Stack, Switch, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQuery } from "@tanstack/react-query";

const tzOptions = tz.map((t) => ({ label: t.displayName, value: t.ianaName }));

const getParam = (params, name) => params.find((p) => p.key == name)?.value;

const CompanyEdit = ({ companyId, onSave, onCancel }) => {
  const form = useForm({
    initialValues: {
      name: "",
      timeZone: "",
      bluStarApiKey: "",
      active: true,
    },
  });

  const { error, isLoading, isError } = useQuery(["companies", companyId], () => api.getCompany(companyId), {
    onSuccess: (data) =>
      form.setValues({
        name: data.name ?? "",
        active: data.active ?? true,
        timeZone: getParam(data.parameters, "timezone_php") ?? "",
        bluStarApiKey: getParam(data.parameters, "api_key") ?? "",
      }),
  });

  const handleSubmit = (values) => {
    onSave(values);
  };

  if (isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput data-autofocus autoFocus label="Company Name" {...form.getInputProps("name")} />
        <Group position="right">
          <Switch
            size="lg"
            onLabel="Active"
            offLabel="Inactive"
            {...form.getInputProps("active", { type: "checkbox" })}
          />
        </Group>
        <Select label="Time Zone" data={tzOptions} {...form.getInputProps("timeZone")} />
        <TextInput label="Blu Star API Key" {...form.getInputProps("bluStarApiKey")} />
        <Group position="center" mt="xl">
          <Button type="submit">Save</Button>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default CompanyEdit;

import { axiosInstance } from "@/api";
import { parseValidationError } from "@/fns";
import useAuth from "@/hooks/useAuth";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const AxiosInterceptors = () => {
  const { onTokenExpired, onLogout } = useAuth();
  const navigate = useNavigate();

  const reqInterceptorId = useRef(null);
  const resInterceptorId = useRef(null);

  useEffect(() => {
    reqInterceptorId.current = axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("accessToken");
        if (token && !config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    resInterceptorId.current = axiosInstance.interceptors.response.use(
      (response) => {
        return response.data;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401) {
          if (originalRequest._retry) {
            onLogout();
            navigate("/auth/login");
          } else {
            originalRequest._retry = true;
            const newToken = await onTokenExpired();
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return axiosInstance(originalRequest);
          }
        } else {
          // non-401 error
          throw new Error(
            error.response.data?.detail || // "Problem"-type result, simple message
              parseValidationError(error.response.data) || // "ValidationProblem"-type result, parse errors
              error.response.data || // Bare error, probably from unhandled exception
              error.message // Fallback for any other error
          );
        }
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(reqInterceptorId.current);
      axiosInstance.interceptors.response.eject(resInterceptorId.current);
    };
  }, []);

  return null;
};

export default AxiosInterceptors;

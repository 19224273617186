import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import { Box, Button, Group, Input, LoadingOverlay, SimpleGrid, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useState } from "react";

const checkNameExists = async (id, name) => {
  try {
    const existing = await api.getAlertScheduleByName(name);
    return existing.scheduleId != id;
  } catch (err) {
    return false;
  }
};

const emptyValue = Array(24 * 7).fill("0");

const dayOfWeek = (i) => ["S", "M", "T", "W", "T", "F", "S"][i / 24];
const hourOfDay = (i) => {
  if (i == 0) return "12a";
  if (i > 12) return (i - 12).toString() + "p";
  return i.toString() + "a";
};

const AlertScheduleEdit = ({ id, onSave, onCancel }) => {
  const [mode, setMode] = useState();

  const form = useForm({
    initialValues: {
      name: "",
      value: emptyValue,
    },
  });

  const { error, isLoading, isError } = useQuery(["alertSchedules", id], () => api.getAlertSchedule(id), {
    onSuccess: (data) =>
      form.setValues({ name: data.name, value: data.value?.length == 24 * 7 ? Array.from(data.value) : emptyValue }),
    enabled: !!id,
  });

  const handleSubmit = async (values) => {
    const nameExists = await checkNameExists(id, values.name);
    if (nameExists) {
      form.setFieldError("name", "Name already exists");
    } else {
      onSave({ ...values, value: values.value.toString().replaceAll(",", "") });
    }
  };

  const toggleValue = (i, initialClick) => {
    let _mode;
    if (initialClick) {
      _mode = form.values.value[i] == "1" ? "turnOff" : "turnOn";
      setMode(_mode);
    } else {
      _mode = mode;
    }
    form.setFieldValue(
      "value",
      form.values.value.map((v, j) => (j == i ? (_mode == "turnOn" ? "1" : "0") : v))
    );
  };

  if (id && isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput required data-autofocus autoFocus label="Name" {...form.getInputProps("name")} />
        <SimpleGrid
          cols={25}
          spacing={0}
          sx={{
            userSelect: "none",
          }}
        >
          <Box id="topLeftEmptyCell" />
          {Array(24)
            .fill("")
            .map((_, i) => (
              <Box
                key={i}
                ta="center"
                sx={(theme) => ({
                  border: "solid",
                  borderWidth: "0 0 1px 0",
                  borderColor: theme.colors.green[6],
                })}
              >
                <Input.Label>{hourOfDay(i)}</Input.Label>
              </Box>
            ))}
          {form.values.value.map((v, i) => {
            const cell = (
              <Box
                sx={(theme) => ({
                  border: "solid",
                  borderWidth: "0 1px 1px 0",
                  borderColor: theme.colors.green[6],
                  backgroundColor: v == "1" ? theme.colors.green[6] : "#fff",
                  minHeight: 30,
                })}
                onMouseDown={() => toggleValue(i, true)}
                onMouseEnter={(e) => {
                  if (e.buttons == 1) toggleValue(i, false);
                }}
              />
            );

            let left = null;
            if (i % 24 == 0) {
              left = (
                <Box
                  ta="center"
                  sx={(theme) => ({
                    border: "solid",
                    borderWidth: "0 1px 0 0",
                    borderColor: theme.colors.green[6],
                  })}
                >
                  <Input.Label>{dayOfWeek(i)}</Input.Label>
                </Box>
              );
            }

            return (
              <Fragment key={i}>
                {left}
                {cell}
              </Fragment>
            );
          })}
        </SimpleGrid>
        <Group position="center" mt="xl">
          <Button type="submit">{id ? "Save" : "Add"}</Button>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default AlertScheduleEdit;

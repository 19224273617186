import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import { Button, Group, LoadingOverlay, MultiSelect, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQuery } from "@tanstack/react-query";

const prepareVehicleOptions = (vehicles) => {
  if (!vehicles || vehicles.length === 0) return [];

  return vehicles.map((v) => ({
    value: v.vehicleId,
    label: v.vehicleName + (v.driverName ? ` (${v.driverName.trim()})` : ""),
  }));
};

const checkNameExists = async (id, name) => {
  try {
    const existing = await api.getVehicleGroupByName(name);
    return existing.groupId != id;
  } catch (err) {
    console.log(err.message);
    return false;
  }
};

const VehicleGroupEdit = ({ id, onSave, onCancel }) => {
  const form = useForm({
    initialValues: {
      name: "",
      vehicleIds: [],
    },
  });

  const { error, isLoading, isError } = useQuery(["vehicleGroups", id], () => api.getVehicleGroup(id), {
    onSuccess: (data) => form.setValues({ name: data.name, vehicleIds: data.vehicles?.map((v) => v.vehicleId) }),
    enabled: !!id,
  });

  const { data: vehicles } = useQuery(["vehicles"], api.getVehicles);

  const handleSubmit = async (values) => {
    const nameExists = await checkNameExists(id, values.name);
    if (nameExists) {
      form.setFieldError("name", "Name already exists");
    } else {
      onSave(values);
    }
  };

  if (id && isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput required data-autofocus autoFocus label="Name" {...form.getInputProps("name")} />
        <MultiSelect
          label="Vehicles"
          data={prepareVehicleOptions(vehicles)}
          searchable
          {...form.getInputProps("vehicleIds")}
        />
        <Group position="center" mt="xl">
          <Button type="submit">{id ? "Save" : "Add"}</Button>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default VehicleGroupEdit;

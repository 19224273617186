import { isInRole } from "@/fns";
import useAuth from "@/hooks/useAuth";
import useIsPhone from "@/hooks/useIsPhone";
import {
  ActionIcon,
  Box,
  Center,
  createStyles,
  Group,
  Navbar as MantineNavbar,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { FaBuilding, FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ConfigLinks, MenuLinks } from "./MenuLinks";

const AdminLinks = [
  { icon: <FaBuilding size="2em" />, route: "/admin/companies", label: "Companies" },
  { icon: <FaMapMarkerAlt size="2em" />, route: "/admin/gpsDevices", label: "GPS Devices" },
];

const useStyles = createStyles((theme, { variant }) => ({
  button: {
    display: "block",
    width: "100%",
    padding: theme.spacing.xs,
    borderRadius: theme.radius.sm,
    color: variant == "admin" ? theme.colors.gray[8] : "white",

    "&:hover": {
      color: "black",
      backgroundColor: theme.colors[variant == "admin" ? "green" : "orange"][5],
      "& .mantine-ActionIcon-root": {
        color: "black",
      },
    },
  },
  adminBox: {
    backgroundColor: theme.colors.gray[3],
    color: theme.colors.gray[8],
  },
  icon: {
    color: theme.colors[variant == "admin" ? "orange" : "green"][6],
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const NavbarLink = ({ icon, route, label, variant = "" }) => {
  const { classes } = useStyles({ variant });
  const navigate = useNavigate();

  return (
    <>
      <UnstyledButton className={classes.button} onClick={() => navigate(route)}>
        <Group>
          <ActionIcon component="div" className={classes.icon}>
            {icon}
          </ActionIcon>
          <Text size={15}>{label}</Text>
        </Group>
      </UnstyledButton>
    </>
  );
};

const Navbar = () => {
  const { classes } = useStyles({ variant: "" });
  const { claims, token } = useAuth();
  const showAdmin = isInRole(claims, "Admin");
  const showSuperAdmin = isInRole(claims, "SuperAdmin");
  const isPhone = useIsPhone();

  if (isPhone || !token) return null;

  return (
    <MantineNavbar
      height="dummy_value_let_browser_compute"
      sx={{ minHeight: "100vh" }}
      width={{ base: 210 }}
      p="xs"
      styles={(theme) => ({
        root: {
          backgroundColor: theme.colors.gray[7],
        },
      })}
    >
      <div>
        {MenuLinks.map((link) => (
          <NavbarLink {...link} key={link.label} />
        ))}
      </div>
      {showAdmin && (
        <Box className={classes.adminBox} mt={20}>
          <Center my={10}>
            <Text weight={700}>Configuration</Text>
          </Center>
          <div>
            {ConfigLinks.map((link) => (
              <NavbarLink variant="admin" {...link} key={link.label} />
            ))}
          </div>
        </Box>
      )}
      {showSuperAdmin && (
        <Box className={classes.adminBox} mt={20}>
          <Center my={10}>
            <Text weight={700}>Administration</Text>
          </Center>
          <div>
            {AdminLinks.map((link) => (
              <NavbarLink variant="admin" {...link} key={link.label} />
            ))}
          </div>
        </Box>
      )}
    </MantineNavbar>
  );
};

export default Navbar;

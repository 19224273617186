import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import SimpleTable from "@/components/SimpleTable";
import { showErrorToast, showSuccessToast } from "@/fns";
import { BREAKPOINTS, useMediaBreakpoint } from "@/hooks/useBreakpoints";
import useIsPhone from "@/hooks/useIsPhone";
import { openVehicleEditModal } from "@/modals/VehicleEditModal";
import { ActionIcon, Group, LoadingOverlay, Menu, Tooltip } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { noop } from "@tanstack/react-table";
import { FaChevronDown, FaExclamationTriangle, FaMapMarkerAlt, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const columnDef = [
  { accessorKey: "vehicleName", header: "Name", metadata: { xwidth: 1 } },
  { accessorKey: "year", header: "Year", metadata: { xwidth: 1 } },
  {
    id: "vehicleMakeModel",
    accessorFn: (row) => `${row.make} ${row.model}`,
    header: "Type",
    metadata: { xwidth: 3 },
  },
  {
    accessorKey: "miles",
    cell: ({ cell }) => cell.getValue().toLocaleString(),
    header: "Mileage",
    enableGlobalFilter: false,
    metadata: { xwidth: 1 },
  },
  { accessorKey: "driverName", header: "Driver", metadata: { xwidth: 2 } },
  { accessorKey: "driverPhoneNumber", header: "Driver Phone #", metadata: { xwidth: 2 } },
];

const columnsToShow = {
  [BREAKPOINTS.xl]: 8,
  [BREAKPOINTS.lg]: 6,
  [BREAKPOINTS.md]: 5,
  [BREAKPOINTS.sm]: 3,
  [BREAKPOINTS.xs]: 3,
};

const ResponsiveMenu = ({ isPhone, children }) =>
  isPhone ? (
    <Menu>
      <Menu.Target>
        <ActionIcon variant="outline" color="brand">
          <FaChevronDown />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  ) : (
    <>{children}</>
  );

const MenuItem = ({ label, icon, isPhone, onClick }) =>
  isPhone ? (
    <Menu.Item icon={icon} onClick={onClick}>
      {label}
    </Menu.Item>
  ) : (
    <Tooltip label={label}>
      <ActionIcon color="green" variant="outline" onClick={onClick}>
        {icon}
      </ActionIcon>
    </Tooltip>
  );

const VehicleList = () => {
  const queryClient = useQueryClient();
  const breakpoint = useMediaBreakpoint();
  const isPhone = useIsPhone();
  const navigate = useNavigate();

  const { data, error, isLoading, isError } = useQuery(["vehicles"], api.getVehicles);

  const handleEditVehicle = (vehicleId) => {
    openVehicleEditModal({ vehicleId, onSave: (data) => handleUpdateVehicle(vehicleId, data) });
  };

  const handleUpdateVehicle = async (vehicleId, data) => {
    try {
      await api.updateVehicle(vehicleId, data);
      showSuccessToast("Saved");
    } catch (err) {
      showErrorToast("Save Failed", err.message);
    } finally {
      queryClient.invalidateQueries(["vehicles"]);
    }
  };

  const handleDeleteVehicle = (id) => {
    openConfirmModal({
      title: "Are you sure you want to delete this vehicle?",
      children:
        "The historic GPS data is tied to the OBD device, not the vehicle.  If you install the OBD device in a new vehicle, the history will be visible under the new vehicle.",
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: noop,
      onConfirm: () => doDeleteVehicle(id),
    });
  };

  const doDeleteVehicle = async (id) => {
    try {
      await api.deleteVehicle(id);
      showSuccessToast("Vehicle Deleted");
    } catch (err) {
      showErrorToast("Error deleting vehicle", err.message);
    } finally {
      queryClient.invalidateQueries(["vehicles"]);
    }
  };

  if (isLoading) return <LoadingOverlay visible />;
  if (isError) return <ErrorAlert message={error.message} />;

  const columns = [
    ...columnDef.slice(0, columnsToShow[breakpoint]),
    {
      id: "actions",
      cell: ({ row }) => (
        <Group position="right" spacing="xs" noWrap>
          <ResponsiveMenu isPhone={isPhone}>
            <MenuItem
              isPhone={isPhone}
              label="Edit Vehicle"
              icon={<FaPencilAlt />}
              onClick={() => handleEditVehicle(row.original.vehicleId)}
            />
            <MenuItem
              isPhone={isPhone}
              label="View on Map"
              icon={<FaMapMarkerAlt />}
              onClick={() => navigate(`/map/vehicle/${row.original.vehicleId}`)}
            />
            <MenuItem
              isPhone={isPhone}
              label="View Alerts"
              icon={<FaExclamationTriangle />}
              onClick={() => navigate(`/map/vehicle/${row.original.vehicleId}/alerts`)}
            />
            {false && (
              <MenuItem
                isPhone={isPhone}
                label="Delete Vehicle"
                icon={<FaTrashAlt />}
                onClick={() => handleDeleteVehicle(row.original.vehicleId)}
              />
            )}
          </ResponsiveMenu>
        </Group>
      ),
      metadata: { align: "right" },
    },
  ];

  return (
    <SimpleTable
      data={data}
      columns={columns}
      title="Vehicles"
      searchable
      initialSort={[{ id: "vehicleName", desc: false }]}
    />
  );
};

export default VehicleList;

import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import { canParseInt } from "@/fns";
import { Button, Group, LoadingOverlay, NumberInput, Select, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQuery } from "@tanstack/react-query";

const AlertRuleEdit = ({ alertData, onSave, onCancel }) => {
  const form = useForm({
    initialValues: {
      recipients: alertData.recipients ?? "",
      scheduleId: alertData.scheduleId ?? "",
      idleDuration: canParseInt(alertData.idleDuration) ? parseInt(alertData.idleDuration) : 10,
    },
  });

  const { data: alertSchedules, error, isLoading } = useQuery(["alertSchedules"], api.getAlertSchedules);

  if (isLoading) return <LoadingOverlay visible />;
  if (error) return <ErrorAlert message={error.message} />;

  return (
    <form onSubmit={form.onSubmit(onSave)}>
      <Stack>
        <TextInput
          data-autofocus
          autoFocus
          label="Recipients"
          description="Separate multiple email addresses with semicolons"
          {...form.getInputProps("recipients")}
        />
        <Select
          label="Schedule"
          clearable
          data={alertSchedules.map((s) => ({ value: s.scheduleId.toString(), label: s.name }))}
          {...form.getInputProps("scheduleId")}
        />
        {alertData.alertKey == "idle" && (
          <NumberInput
            hideControls
            label="Minimum Duration before Alert is Sent"
            rightSection={<Text size="sm">minutes</Text>}
            rightSectionWidth={75}
            {...form.getInputProps("idleDuration")}
          />
        )}
        <Group position="center" mt="xl">
          <Button type="submit">Save</Button>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default AlertRuleEdit;

import ReportScheduler from "@/pages/reports/ReportScheduler";
import { openContextModal } from "@mantine/modals";

const ReportSchedulerModal = ({ context, id, innerProps }) => {
  const handleSave = (data) => {
    context.closeModal(id);
    innerProps.onSave(data);
  };

  const handleCancel = () => {
    context.closeModal(id);
  };

  return <ReportScheduler data={innerProps.data} onSave={handleSave} onCancel={handleCancel} />;
};

const openReportSchedulerModal = (innerProps) => {
  openContextModal({
    modal: "ReportSchedulerModal",
    size: "lg",
    title: `Schedule the ${innerProps.title}`,
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    innerProps,
  });
};

export { ReportSchedulerModal, openReportSchedulerModal };

import { TextInput } from "@mantine/core";
import InputMask from "react-input-mask";

const PhoneInput = ({ label, ...rest }) => (
  <InputMask mask="999-999-9999" {...rest}>
    {(inputProps) => <TextInput label={label} {...inputProps} />}
  </InputMask>
);

export default PhoneInput;

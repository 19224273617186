import { createStyles, SimpleGrid } from "@mantine/core";
import { forwardRef } from "react";

const useStyles = createStyles((theme) => ({
  list: {
    cursor: "pointer",
    "& > *": {
      height: "35px",
      padding: "6px",
      borderStyle: "solid",
      borderWidth: "1px 1px 0 1px",
      borderColor: theme.colors.gray[3],
      "&:hover": {
        backgroundColor: theme.colors.gray[1],
      },
      "&:nth-last-of-type(1)": {
        borderBottomWidth: "1px",
      },
    },
  },
}));

const forwardRefComponent = ({ children, ...rest }, ref) => {
  const { classes } = useStyles();

  return (
    <SimpleGrid cols={1} spacing={0} className={classes.list} {...rest} ref={ref}>
      {children}
    </SimpleGrid>
  );
};

forwardRefComponent.displayName = "SimpleList";

const SimpleList = forwardRef(forwardRefComponent);

export default SimpleList;

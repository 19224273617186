import * as api from "@/api";
import ErrorAlert from "@/components/ErrorAlert";
import { LoadingOverlay, Stack } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import AlertRuleList from "./AlertRuleList";
import AlertScheduleList from "./AlertScheduleList";

const AlertIndex = () => {
  const { data: alertSchedules, error, isLoading } = useQuery(["alertSchedules"], api.getAlertSchedules);

  if (isLoading) return <LoadingOverlay visible />;
  if (error) return <ErrorAlert message={error.message} />;

  return (
    <Stack spacing="xl">
      <div>
        <AlertRuleList alertSchedules={alertSchedules} />
      </div>
      <div>
        <AlertScheduleList alertSchedules={alertSchedules} />
      </div>
    </Stack>
  );
};

export default AlertIndex;

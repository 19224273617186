import * as api from "@/api";
import { downloadUrl, isInRole, showErrorToast, showSuccessToast } from "@/fns";
import useAuth from "@/hooks/useAuth";
import { openReportSchedulerModal } from "@/modals/ReportSchedulerModal";
import { Group, LoadingOverlay, Select, SimpleGrid, TextInput } from "@mantine/core";
import dayjs from "dayjs";
import { useState } from "react";
import { QuickDateRangeSetter, QuickDateSetter, ReportCard, ReportDateParameter } from "./ReportMenuComponents";

const yesterday = dayjs().subtract(1, "day").toDate();
const firstOfMonth = dayjs().startOf("month").toDate();
const lastOfMonth = dayjs().endOf("month").toDate();

const ReportMenu = () => {
  const { claims } = useAuth();
  const isSuperAdmin = isInRole(claims, "SuperAdmin");

  const [params, setParams] = useState({
    alertSummaryDate: yesterday,
    alertSummarySortBy: "LowScoreFirst",
    alertsPerMileStartDate: firstOfMonth,
    alertsPerMileEndDate: lastOfMonth,
    alertsPerMileMinMiles: "1000",
    alertsPerMileSortBy: "BestFirst",
    engineHoursStartDate: firstOfMonth,
    engineHoursEndDate: lastOfMonth,
    mileageStartDate: firstOfMonth,
    mileageEndDate: lastOfMonth,
    tripsStartDate: firstOfMonth,
    tripsEndDate: lastOfMonth,
    stopsStartDate: firstOfMonth,
    stopsEndDate: lastOfMonth,
    idlingStartDate: firstOfMonth,
    idlingEndDate: lastOfMonth,
    crossingsStartDate: firstOfMonth,
    crossingsEndDate: lastOfMonth,
    assetBillingStartDate: firstOfMonth,
    assetBillingEndDate: lastOfMonth,
  });

  const [isLoading, setIsLoading] = useState(false);

  const updateParams = (key, value) => setParams((last) => ({ ...last, [key]: value }));

  const handleRunReport = async (name, data, format = "pdf") => {
    try {
      setIsLoading(true);
      const url = await api.runReport(name, data, format);

      if (format == "pdf") {
        window.open(url);
      } else {
        downloadUrl(url, name);
      }
    } catch (e) {
      showErrorToast("Error running report", e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRunAlertSummary = async (format) =>
    await handleRunReport(
      "alertSummary",
      {
        date: dayjs(params.alertSummaryDate).format("YYYY-MM-DD"),
        sortDescending: params.alertSummarySortBy == "HighScoreFirst",
      },
      format
    );

  const handleRunAlertsPerMile = async (format) =>
    await handleRunReport(
      "alertsPerMile",
      {
        startDate: dayjs(params.alertsPerMileStartDate).format("YYYY-MM-DD"),
        endDate: dayjs(params.alertsPerMileEndDate).format("YYYY-MM-DD"),
        minimumMiles: params.alertsPerMileMinMiles,
        sort: params.alertsPerMileSortBy,
      },
      format
    );

  const handleRunEngineHours = async (format) =>
    await handleRunReport(
      "engineHours",
      {
        startDate: dayjs(params.engineHoursStartDate).format("YYYY-MM-DD"),
        endDate: dayjs(params.engineHoursEndDate).format("YYYY-MM-DD"),
      },
      format
    );

  const handleRunMileage = async (format) =>
    await handleRunReport(
      "mileage",
      {
        startDate: dayjs(params.mileageStartDate).format("YYYY-MM-DD"),
        endDate: dayjs(params.mileageEndDate).format("YYYY-MM-DD"),
      },
      format
    );

  const handleRunTrips = async (format) =>
    await handleRunReport(
      "trips",
      {
        startDate: dayjs(params.tripsStartDate).format("YYYY-MM-DD"),
        endDate: dayjs(params.tripsEndDate).format("YYYY-MM-DD"),
      },
      format
    );

  const handleRunStops = async (format) =>
    await handleRunReport(
      "stops",
      {
        startDate: dayjs(params.stopsStartDate).format("YYYY-MM-DD"),
        endDate: dayjs(params.stopsEndDate).format("YYYY-MM-DD"),
      },
      format
    );

  const handleRunIdling = async (format) =>
    await handleRunReport(
      "idling",
      {
        startDate: dayjs(params.idlingStartDate).format("YYYY-MM-DD"),
        endDate: dayjs(params.idlingEndDate).format("YYYY-MM-DD"),
      },
      format
    );

  const handleRunCrossings = async (format) =>
    await handleRunReport(
      "stateLineCrossings",
      {
        startDate: dayjs(params.crossingsStartDate).format("YYYY-MM-DD"),
        endDate: dayjs(params.crossingsEndDate).format("YYYY-MM-DD"),
      },
      format
    );

  const handleRunAssetBiling = async (format) =>
    await handleRunReport(
      "assetBilling",
      {
        startDate: dayjs(params.assetBillingStartDate).format("YYYY-MM-DD"),
        endDate: dayjs(params.assetBillingEndDate).format("YYYY-MM-DD"),
      },
      format
    );

  const handleScheduleReport = async (title, name, additionalParams) => {
    openReportSchedulerModal({ title, name, onSave: (data) => doScheduleReport(title, name, data, additionalParams) });
  };

  const doScheduleReport = (title, name, data, additionalParams) => {
    try {
      api.createScheduledReport({
        reportName: name,
        description: title,
        relativeDate: data.relativeDate,
        emailTo: data.emailTo,
        cronExpression: data.cronExpression,
        additionalParams,
      });
      showSuccessToast("Report scheduled successfully");
    } catch (e) {
      showErrorToast("Error scheduling report", e.message);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <LoadingOverlay visible={isLoading} />
      <SimpleGrid
        maw={1100}
        cols={3}
        breakpoints={[
          { maxWidth: 1000, cols: 1 },
          { maxWidth: 1300, cols: 2 },
        ]}
      >
        <ReportCard
          title="Alert Summary Report"
          description="A count of alerts and a driving score for each vehicle"
          onClick={handleRunAlertSummary}
          onSchedule={() =>
            handleScheduleReport("Alert Summary Report", "alertSummary", {
              sortDescending: params.alertSummarySortBy == "HighScoreFirst" ? "true" : "false",
            })
          }
        >
          <Group mt="sm" position="center">
            <ReportDateParameter
              label="Date"
              value={params.alertSummaryDate}
              onChange={(val) => updateParams("alertSummaryDate", val)}
            />
            <Select
              label="Sort By"
              value={params.alertSummarySortBy}
              onChange={(val) => updateParams("alertSummarySortBy", val)}
              data={[
                { value: "LowScoreFirst", label: "Lowest Score" },
                { value: "HighScoreFirst", label: "Highest Score" },
              ]}
              w="150px"
              styles={{
                root: { textAlign: "center" },
              }}
            />
          </Group>
          <QuickDateSetter fieldName="alertSummaryDate" setterFn={updateParams} />
        </ReportCard>

        <ReportCard
          title="Engine Hours Report"
          description="Ignition-On hours per vehicle per day"
          onClick={handleRunEngineHours}
          onSchedule={() => handleScheduleReport("Engine Hours Report", "engineHours")}
        >
          <Group mt="sm" position="center">
            <ReportDateParameter
              label="Start Date"
              value={params.engineHoursStartDate}
              onChange={(val) => updateParams("engineHoursStartDate", val)}
            />
            <ReportDateParameter
              label="End Date"
              value={params.engineHoursEndDate}
              onChange={(val) => updateParams("engineHoursEndDate", val)}
            />
          </Group>
          <QuickDateRangeSetter
            startfieldName="engineHoursStartDate"
            endFieldName="engineHoursEndDate"
            setterFn={updateParams}
          />
        </ReportCard>

        <ReportCard
          title="Mileage Report"
          description="Miles driven per vehicle per day"
          onClick={handleRunMileage}
          onSchedule={() => handleScheduleReport("Mileage Report", "mileage")}
        >
          <Group mt="sm" position="center">
            <ReportDateParameter
              label="Start Date"
              value={params.mileageStartDate}
              onChange={(val) => updateParams("mileageStartDate", val)}
            />
            <ReportDateParameter
              label="End Date"
              value={params.mileageEndDate}
              onChange={(val) => updateParams("mileageEndDate", val)}
            />
          </Group>
          <QuickDateRangeSetter
            startfieldName="mileageStartDate"
            endFieldName="mileageEndDate"
            setterFn={updateParams}
          />
        </ReportCard>

        <ReportCard
          title="Trips Report"
          description="Trips driven from ignition-on to ignition-off"
          onClick={handleRunTrips}
          onSchedule={() => handleScheduleReport("Trips Report", "trips")}
        >
          <Group mt="sm" position="center">
            <ReportDateParameter
              label="Start Date"
              value={params.tripsStartDate}
              onChange={(val) => updateParams("tripsStartDate", val)}
            />
            <ReportDateParameter
              label="End Date"
              value={params.tripsEndDate}
              onChange={(val) => updateParams("tripsEndDate", val)}
            />
          </Group>
          <QuickDateRangeSetter startfieldName="tripsStartDate" endFieldName="tripsEndDate" setterFn={updateParams} />
        </ReportCard>

        <ReportCard
          title="Stops Report"
          description="Times and locations of ignition-off intervals"
          onClick={handleRunStops}
          onSchedule={() => handleScheduleReport("Stops Report", "stops")}
        >
          <Group mt="sm" position="center">
            <ReportDateParameter
              label="Start Date"
              value={params.stopsStartDate}
              onChange={(val) => updateParams("stopsStartDate", val)}
            />
            <ReportDateParameter
              label="End Date"
              value={params.stopsEndDate}
              onChange={(val) => updateParams("stopsEndDate", val)}
            />
          </Group>
          <QuickDateRangeSetter startfieldName="stopsStartDate" endFieldName="stopsEndDate" setterFn={updateParams} />
        </ReportCard>

        <ReportCard
          title="Idling Report"
          description="Times and locations of long periods of vehicle idling"
          onClick={handleRunIdling}
          onSchedule={() => handleScheduleReport("Idling Report", "idling")}
        >
          <Group mt="sm" position="center">
            <ReportDateParameter
              label="Start Date"
              value={params.idlingStartDate}
              onChange={(val) => updateParams("idlingStartDate", val)}
            />
            <ReportDateParameter
              label="End Date"
              value={params.idlingEndDate}
              onChange={(val) => updateParams("idlingEndDate", val)}
            />
          </Group>
          <QuickDateRangeSetter startfieldName="idlingStartDate" endFieldName="idlingEndDate" setterFn={updateParams} />
        </ReportCard>

        <ReportCard
          title="State Line Crossings Report"
          description="Times of vehicles crossing from one state to another"
          onClick={handleRunCrossings}
          onSchedule={() => handleScheduleReport("State Line Crossings Report", "crossings")}
        >
          <Group mt="sm" position="center">
            <ReportDateParameter
              label="Start Date"
              value={params.crossingsStartDate}
              onChange={(val) => updateParams("crossingsStartDate", val)}
            />
            <ReportDateParameter
              label="End Date"
              value={params.crossingsEndDate}
              onChange={(val) => updateParams("crossingsEndDate", val)}
            />
          </Group>
          <QuickDateRangeSetter
            startfieldName="crossingsStartDate"
            endFieldName="crossingsEndDate"
            setterFn={updateParams}
          />
        </ReportCard>
        <ReportCard
          title="Alerts per Mile Report"
          description="Multi-Day Version of Alert Summary Report"
          onClick={handleRunAlertsPerMile}
          onSchedule={() =>
            handleScheduleReport("Alerts per Mile Report", "alertsPerMile", {
              minimumMiles: params.alertsPerMileMinMiles,
              sort: params.alertsPerMileSortBy,
            })
          }
        >
          <Group mt="sm" position="center">
            <ReportDateParameter
              label="Start Date"
              value={params.alertsPerMileStartDate}
              onChange={(val) => updateParams("alertsPerMileStartDate", val)}
            />
            <ReportDateParameter
              label="End Date"
              value={params.alertsPerMileEndDate}
              onChange={(val) => updateParams("alertsPerMileEndDate", val)}
            />
          </Group>
          <Group mt={0} position="center">
            <TextInput
              type="number"
              label="Min Miles"
              value={params.alertsPerMileMinMiles}
              onChange={(e) => updateParams("alertsPerMileMinMiles", e.currentTarget.value)}
              w="120px"
              styles={{
                root: { textAlign: "center" },
                input: { textAlign: "center" },
              }}
            />
            <Select
              label="Sort By"
              value={params.alertsPerMileSortBy}
              onChange={(val) => updateParams("alertsPerMileSortBy", val)}
              data={[
                { value: "BestFirst", label: "Best Score" },
                { value: "WorstFirst", label: "Worst Score" },
              ]}
              w="120px"
              styles={{
                root: { textAlign: "center" },
              }}
            />
          </Group>
          <QuickDateRangeSetter
            startfieldName="alertsPerMileStartDate"
            endFieldName="alertsPerMileEndDate"
            setterFn={updateParams}
          />
        </ReportCard>

        {isSuperAdmin && (
          <ReportCard
            title="Asset Billing Report"
            description="Count of Active GPS Devices per Client"
            onClick={handleRunAssetBiling}
            onSchedule={() => handleScheduleReport("Asset Billing Report", "assetBilling")}
          >
            <Group mt="sm" position="center">
              <ReportDateParameter
                label="Start Date"
                value={params.assetBillingStartDate}
                onChange={(val) => updateParams("assetBillingStartDate", val)}
              />
              <ReportDateParameter
                label="End Date"
                value={params.assetBillingEndDate}
                onChange={(val) => updateParams("assetBillingEndDate", val)}
              />
            </Group>
            <QuickDateRangeSetter
              startfieldName="assetBillingStartDate"
              endFieldName="assetBillingEndDate"
              setterFn={updateParams}
            />
          </ReportCard>
        )}
      </SimpleGrid>
    </div>
  );
};

export default ReportMenu;

import { showErrorToast } from "@/fns";
import useAuth from "@/hooks/useAuth";
import { Alert, Button, Center, Container, Group, Paper, Text, TextInput, Title } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const usernameRef = useRef();
  const { token, onForgotPassword } = useAuth();
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (token) navigate("/");
  }, []);

  const handleSubmit = async () => {
    try {
      await onForgotPassword(usernameRef.current.value);
      setSuccess(true);
    } catch (err) {
      showErrorToast("Reset Password Failed", err.message);
    }
  };

  return (
    <Container size={300} mt={50}>
      <Paper p="md" shadow="xs">
        {success ? (
          <Alert color="green" title="Check your email for a link to reset your password" />
        ) : (
          <>
            <Title order={4}>Forgot Password</Title>
            <Text size="sm" mb={10}>
              Enter your email address and we will email you a link to reset your password
            </Text>
            <TextInput label="Email Address" autoFocus required ref={usernameRef} />
            <Group position="center">
              <Button type="submit" mt={10} onClick={handleSubmit}>
                Reset Password
              </Button>
            </Group>
            <Center mt={10}>
              <Text size="sm">
                <Link to="/auth/login">Back to Log In</Link>
              </Text>
            </Center>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default ForgotPassword;

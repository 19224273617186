import GpsDeviceEdit from "@/pages/admin/gpsDevices/GpsDeviceEdit";
import { openContextModal } from "@mantine/modals";

const GpsDeviceEditModal = ({ context, id, innerProps }) => {
  const handleSave = (data) => {
    context.closeModal(id);
    innerProps.onSave(data);
  };

  const handleCancel = () => {
    context.closeModal(id);
  };

  return <GpsDeviceEdit vehicleId={innerProps.vehicleId} onSave={handleSave} onCancel={handleCancel} />;
};

const openGpsDeviceEditModal = (innerProps) => {
  openContextModal({
    modal: "GpsDeviceEditModal",
    size: "xl",
    title: `${innerProps.vehicleId ? "Edit" : "Add"} GPS Device`,
    centered: true,
    withCloseButton: true,
    closeOnClickOutside: false,
    closeOnEscape: false,
    innerProps,
  });
};

export { GpsDeviceEditModal, openGpsDeviceEditModal };

import PhoneInput from "@/components/PhoneInput";
import tz from "@/tz";
import { Button, Group, Paper, PasswordInput, Select, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

const tzOptions = tz.map((t) => ({ label: t.displayName, value: t.ianaName }));

const CompanyAdd = ({ onSave, onCancel }) => {
  const form = useForm({
    initialValues: {
      companyName: "",
      timeZone: "",
      adminName: "",
      adminEmail: "",
      adminPhoneNumber: "",
      adminPassword: "",
    },
    validate: {
      timeZone: (value) => (value ? null : "Required"),
    },
  });

  const handleSubmit = (values) => {
    onSave({
      name: values.companyName,
      timeZone: values.timeZone,
      adminUser: {
        name: values.adminName,
        email: values.adminEmail,
        phoneNumber: values.adminPhoneNumber,
        username: values.adminEmail,
        password: values.adminPassword,
      },
    });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput required data-autofocus autoFocus label="Company Name" {...form.getInputProps("companyName")} />
        <Select required label="Time Zone" data={tzOptions} {...form.getInputProps("timeZone")} />
        <Paper shadow="xs" p="md">
          <Text size="sm" weight={700}>
            Administrator User
          </Text>
          <TextInput required label="Name" {...form.getInputProps("adminName")} />
          <TextInput required label="Email" {...form.getInputProps("adminEmail")} />
          <PhoneInput label="Phone #" {...form.getInputProps("adminPhoneNumber")} />
          <PasswordInput required label="Password" {...form.getInputProps("adminPassword")} />
        </Paper>
        <Group position="center" mt="xl">
          <Button type="submit">Add</Button>
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default CompanyAdd;

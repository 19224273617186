import * as api from "@/api";
import useQuerystring from "@/hooks/useQuerystring";
import { jwtDecode } from "jwt-decode";
import { createContext, useEffect, useState } from "react";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const qs = useQuerystring();

  const [token, setToken] = useState();
  const [claims, setClaims] = useState();
  const [refreshToken, setRefreshToken] = useState();

  const [tokenLoading, setTokenLoading] = useState(true);
  const [invalidAuthToken, setInvalidAuthToken] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const authToken = qs.get("authToken");

    if (authToken) {
      handleTokenLogin(authToken);
    } else if (accessToken || refreshToken) {
      handleValidateToken(accessToken, refreshToken);
    } else {
      setTokenLoading(false);
    }
  }, []);

  const updateTokens = (accessToken, refreshToken) => {
    setToken(accessToken);
    setRefreshToken(refreshToken);
    setClaims(accessToken ? jwtDecode(accessToken) : null);

    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
    } else {
      localStorage.removeItem("accessToken");
    }

    if (refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
    } else {
      localStorage.removeItem("refreshToken");
    }
  };

  const handleTokenExpired = async () => {
    const _token = token ?? localStorage.getItem("accessToken");
    const _refreshToken = refreshToken ?? localStorage.getItem("refreshToken");

    const refreshResp = await api.refreshToken({ accessToken: _token, refreshToken: _refreshToken });
    updateTokens(refreshResp.accessToken, refreshResp.refreshToken);
    return refreshResp.accessToken;
  };

  const handleLogin = async ({ username, password }) => {
    var res = await api.login({ username, password });
    updateTokens(res.accessToken, res.refreshToken);
  };

  const handleValidateToken = async (accessToken, refreshToken) => {
    try {
      var res = await api.validateToken({ accessToken, refreshToken });
      updateTokens(res.accessToken, res.refreshToken);
    } catch (e) {
      setToken();
    } finally {
      setTokenLoading(false);
    }
  };

  const handleTokenLogin = async (authToken) => {
    try {
      var res = await api.tokenLogin(authToken);
      updateTokens(res.accessToken, res.refreshToken);
    } catch (e) {
      setInvalidAuthToken(true);
    } finally {
      setTokenLoading(false);
    }
  };

  const handleImpersonate = async (companyId) => {
    var res = await api.impersonate(companyId);
    updateTokens(res.accessToken, res.refreshToken);
  };

  const handleChangePassword = async (username, password, newPassword) => {
    await api.changePassword({ username, password, newPassword });
  };

  const handleLogout = () => {
    updateTokens(null, null);
  };

  const handleForgotPassword = async (email) => {
    await api.forgotPassword(email);
  };

  const handleResetPassword = async (email, token, newPassword) => {
    await api.resetPassword({ email, token, password: newPassword });
  };

  const value = {
    token,
    tokenLoading,
    invalidAuthToken,
    claims,
    onTokenExpired: handleTokenExpired,
    onLogin: handleLogin,
    onTokenLogin: handleTokenLogin,
    onImpersonate: handleImpersonate,
    onLogout: handleLogout,
    onForgotPassword: handleForgotPassword,
    onResetPassword: handleResetPassword,
    onChangePassword: handleChangePassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };

import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const BREAKPOINTS = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
};

const useMediaBreakpoint = () => {
  const theme = useMantineTheme();

  const xl = useMediaQuery(theme.fn.largerThan("xl").replace("@media ", ""));
  const lg = useMediaQuery(theme.fn.largerThan("lg").replace("@media ", ""));
  const md = useMediaQuery(theme.fn.largerThan("md").replace("@media ", ""));
  const sm = useMediaQuery(theme.fn.largerThan("sm").replace("@media ", ""));

  return xl ? BREAKPOINTS.xl : lg ? BREAKPOINTS.lg : md ? BREAKPOINTS.md : sm ? BREAKPOINTS.sm : BREAKPOINTS.xs;
};

export { useMediaBreakpoint, BREAKPOINTS };
